import PublishedIcon from "@mui/icons-material/CheckCircleOutline"
import LiveIcon from "@mui/icons-material/RecordVoiceOverOutlined"
import UnpublishedIcon from "@mui/icons-material/RemoveCircleOutline"
import UnfeaturedIcon from "@mui/icons-material/StarBorderRounded"
import FeaturedIcon from "@mui/icons-material/StarRounded"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled
} from "@mui/material"
import type { RootState } from "core/reducer"
import type { FC } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import type { Material, Story } from "@considr-it/ponder-entities"

import { useGlobal } from "shared/hooks/use-global"

import {
  fetchMaterials,
  setActiveMaterial
} from "features/editor/redux/editor-slice"

import {
  materialAccessIconMap,
  materialAccessStringMap
} from "./material-access-editor"

const MaterialItemIcon = styled(ListItemIcon)`
  min-width: 32px;
`

const NameButton = styled(ListItemText)`
  overflow: hidden;
  white-space: nowrap;
`

type BaseMaterialListProps = {
  materials?: Material[]
  activeMaterial?: Material
  onPress?: (m: Material) => void
  showUnpublished?: boolean
}

export const BaseMaterialList: FC<BaseMaterialListProps> = ({
  materials,
  activeMaterial,
  onPress,
  showUnpublished = true
}) => {
  const { isAdmin } = useGlobal()

  return (
    <List>
      {materials?.length === 0 && (
        <ListItem disabled>
          <NameButton primary={"No material available"} />
        </ListItem>
      )}
      {materials?.map((material) => {
        if (!showUnpublished && !material.published) return
        const published = material.published as Story
        const publishedName = published?.name || "Unpublished"
        return (
          <ListItemButton
            key={material.id}
            title={publishedName}
            selected={material.id === activeMaterial?.id}
            onClick={() => onPress(material)}>
            {isAdmin && (
              <MaterialItemIcon
                title={material.featured ? "Featured" : "Unfeatured"}>
                {material.featured ? <FeaturedIcon /> : <UnfeaturedIcon />}
              </MaterialItemIcon>
            )}
            {published ? (
              <MaterialItemIcon title={published.live ? "Live" : "Published"}>
                {published.live ? (
                  <LiveIcon color="error" />
                ) : (
                  <PublishedIcon />
                )}
              </MaterialItemIcon>
            ) : (
              <MaterialItemIcon title="Unpublished">
                <UnpublishedIcon />
              </MaterialItemIcon>
            )}
            <MaterialItemIcon title={materialAccessStringMap[material.access]}>
              {materialAccessIconMap[material.access]}
            </MaterialItemIcon>

            <NameButton
              primary={publishedName}
              primaryTypographyProps={{
                color: published?.name ? "textPrimary" : "textSecondary"
              }}
            />
          </ListItemButton>
        )
      })}
    </List>
  )
}

export const EditorMaterialList = () => {
  const dispatch = useDispatch()

  const materials = useSelector((state: RootState) => state.editor.materials)

  const activeMaterial = useSelector(
    (state: RootState) => state.editor.activeMaterial
  )

  useEffect(() => {
    if (materials !== null) return

    dispatch(fetchMaterials())
  }, [materials, dispatch])

  return (
    <BaseMaterialList
      onPress={(material) => dispatch(setActiveMaterial(material))}
      materials={materials}
      activeMaterial={activeMaterial}
    />
  )
}
