import type { SelectProps } from "@mui/material"
import { FormControl, InputLabel, Select } from "@mui/material"
import type { FC } from "react"

import { StoryType } from "@considr-it/ponder-enums"

export const StoryEditorTypeSelect: FC<
  SelectProps & {
    defaultOption?: string
  }
> = ({ style, ...props }) => {
  return (
    <FormControl focused style={style}>
      <InputLabel
        style={{
          position: "absolute",
          top: -6,
          left: 8,
          background: "white",
          zIndex: 10,
          padding: "0 8px"
        }}>
        {props.label}
      </InputLabel>

      <Select
        variant="outlined"
        native
        style={{
          height: 40
        }}
        {...props}>
        <option key={0} value={StoryType.Questioning}>
          {StoryType.Questioning}
        </option>
        <option key={1} value={StoryType.Writing}>
          {StoryType.Writing}
        </option>
      </Select>
    </FormControl>
  )
}
