import type { RootState } from "core/reducer"
import { useSelector } from "react-redux"

import type { Story } from "@considr-it/ponder-entities"

import { usePonderServerSWR } from "shared/hooks/use-ponder-server"

export const useStoryViewer = ({
  onSelector = (state: RootState) => state.editor
}) => {
  const material = useSelector(
    (state: RootState) => onSelector(state).activeMaterial
  )

  const isSaving = useSelector((state: RootState) => onSelector(state).isSaving)

  const isLoading = useSelector(
    (state: RootState) => onSelector(state).isLoading
  )

  const published = useSelector(
    (state: RootState) => onSelector(state).activeMaterial?.published as Story
  )

  const materials = useSelector(
    (state: RootState) => onSelector(state).materials
  )

  const { data: story, revalidate: revalidateStory } =
    usePonderServerSWR<Story>(
      material?.id ? `story?materialId=${material?.id}` : null
    )

  return {
    material,
    materials,
    story,
    revalidateStory,
    isSaving,
    isLoading,
    published
  }
}
