import { SearchRounded } from "@mui/icons-material"
import { Paper, TextField, InputAdornment, styled } from "@mui/material"

const ClientListHeader = styled(Paper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  z-index: 10;
  padding: ${(p) => p.theme.spacing(1)};
`

export const ClientListBar = ({ filter, setFilter }) => {
  return (
    <ClientListHeader>
      <TextField
        label="Filter user by email"
        variant="outlined"
        size="small"
        fullWidth
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchRounded />
            </InputAdornment>
          )
        }}
      />
    </ClientListHeader>
  )
}
