import { useFormik } from "formik"
import { createContext, useContext, useEffect, useState } from "react"
import { v1 as uuid } from "uuid"

import type { StoryButton, StoryItem } from "@considr-it/ponder-entities"
import { StoryItemLayout } from "@considr-it/ponder-enums"
import { Validator } from "@considr-it/ponder-shared"

import { useGlobal } from "shared/hooks/use-global"
import { usePonderServerSWR } from "shared/hooks/use-ponder-server"

import { useStoryEditor } from "./use-story-editor"

export const useStoryItemEditorProvider = (itemId: string) => {
  const { setDirtyItemMap, setNeedAudioMap, setItemHeaderMap, save, story } =
    useStoryEditor()
  const [otherDirty, setOtherDirty] = useState(false)
  const [showExpandedView, setShowExpandedView] = useState(false)
  const [layout, setLayoutBase] = useState(StoryItemLayout.Default)
  const [buttonMap, setButtonMapBase] = useState<{ [k: string]: StoryButton }>(
    {}
  )
  const { transport } = useGlobal()
  const [exit, setExit] = useState<StoryButton>()

  const {
    data: item,
    revalidate,
    isValidating
  } = usePonderServerSWR<StoryItem>(`story/item?id=${itemId}`, {
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
    onSuccess({ data }) {
      // console.log(data)
      setExit(data.exit)
      setLayoutBase(data.layout)
      setButtonMapBase(
        data.buttons.reduce((map, btn) => {
          map[uuid()] = btn
          return map
        }, {})
      )
    }
  })

  const storyItemForm = useFormik({
    initialValues: {
      prompt: item?.prompt || "",
      header: item?.header || "",
      goto: item?.goto || "",
      transcript: item?.transcript || "",
      aiSummaryPrompt: item?.aiSummaryPrompt || ""
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: Validator.Story.item,
    onSubmit: async (data) => {
      if (
        item.manuallyEditedTranscript !== true &&
        (data.transcript === item?.transcript || data.transcript === "")
      ) {
        storyItemForm.touched.transcript = false
      }
      await Promise.all([
        transport.patch("story/item", {
          id: item.id,
          manuallyEditedTranscript: Boolean(storyItemForm.touched.transcript),
          ...data,
          layout,
          exit,
          buttons: Object.values(buttonMap)
        }),
        story.published &&
          save({
            published: null
          })
      ])

      setOtherDirty(false)
      revalidate()
    }
  })

  const setLayout: typeof setLayoutBase = (e) => {
    setOtherDirty(true)
    setLayoutBase(e)
  }

  const toggleExpandedView = () => {
    setShowExpandedView(!showExpandedView)
  }

  const setButtonMap: typeof setButtonMapBase = (e) => {
    setOtherDirty(true)
    setButtonMapBase(e)
  }

  const isDirty = storyItemForm.dirty || otherDirty

  useEffect(() => {
    setDirtyItemMap((m) => ({
      ...m,
      [itemId]: isDirty || !item?.prompt ? item?.header : null
    }))

    return () => {
      setDirtyItemMap((m) => ({
        ...m,
        [itemId]: null
      }))
    }
  }, [isDirty, setDirtyItemMap, itemId, item?.prompt, item?.header])

  useEffect(() => {
    setNeedAudioMap((m) => ({
      ...m,
      [itemId]: !item?.hasAudio ? item?.header : null
    }))

    return () => {
      setNeedAudioMap((m) => ({
        ...m,
        [itemId]: null
      }))
    }
  }, [setNeedAudioMap, itemId, item?.hasAudio, item?.header])

  useEffect(() => {
    setItemHeaderMap((m) => ({
      ...m,
      [itemId]: item?.header
    }))

    return () => {
      setItemHeaderMap((m) => ({
        ...m,
        [itemId]: null
      }))
    }
  }, [setItemHeaderMap, itemId, item?.header])

  return {
    item,
    revalidate,
    isValidating,
    storyItemForm,
    isDirty,
    layout,
    setLayout,
    setButtonMap,
    buttonMap,
    setOtherDirty,
    toggleExpandedView,
    showExpandedView,
    exit,
    setExit
  }
}

export type StoryItemEditorHook = ReturnType<typeof useStoryItemEditorProvider>

export const StoryItemEditorContext = createContext<StoryItemEditorHook>(null)

export const useStoryItemEditor = () => useContext(StoryItemEditorContext)
