import { Button, ListItemButton } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import List from "@mui/material/List"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemText from "@mui/material/ListItemText"
import { styled } from "@mui/material/styles"
import type { RootState } from "core/reducer"
import type { FC } from "react"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import type { User } from "@considr-it/ponder-entities"

import { useGlobal } from "shared/hooks/use-global"

import {
  fetchAuthors,
  setActiveAuthor,
  updateCoachRanking
} from "../redux/explore-slice"

const RankSaveButton = styled(Button)`
  position: fixed;
  left: 16px;
  bottom: 16px;
`

const RankInput = styled("input")`
  position: absolute;
  right: 4px;
  width: 32px;
`

const AuthorName = styled(ListItemText)`
  overflow: hidden;
  white-space: nowrap;
`

const AuthorAvatar = styled(Avatar)`
  width: ${(props) => props.theme.spacing(3)};
  height: ${(props) => props.theme.spacing(3)};
`

type AuthorListProps = {
  activeAuthor: User
  onAuthorPressed: (a: User) => void
}

export const AuthorList: FC<AuthorListProps> = ({
  activeAuthor,
  onAuthorPressed
}) => {
  const dispatch = useDispatch()

  const authors = useSelector((state: RootState) => state.explore.authors)

  const { isAdmin } = useGlobal()

  const rankMapRef = useRef({})
  const [rankingDirty, setRankingDirty] = useState(false)

  useEffect(() => {
    if (authors === null) {
      dispatch(fetchAuthors())
    } else {
      rankMapRef.current = authors.reduce((p, author) => {
        p[author.id] = author.ranking
        return p
      }, {})
    }
  }, [authors, dispatch])

  return (
    <List>
      {authors?.map((author) => (
        <ListItemButton
          key={author.id}
          selected={author.id === activeAuthor?.id}
          onClick={() => onAuthorPressed(author)}>
          <ListItemAvatar>
            <AuthorAvatar
              alt={author.profile.name}
              src={author.profile.imageUrl}
            />
          </ListItemAvatar>

          <AuthorName primary={author.profile.name} />
          {isAdmin && (
            <RankInput
              defaultValue={rankMapRef.current[author.id]}
              onClick={(e) => {
                e.stopPropagation()
              }}
              onChange={(e) => {
                setRankingDirty(true)
                rankMapRef.current[author.id] = parseInt(e.target.value)
              }}
            />
          )}
        </ListItemButton>
      ))}
      {isAdmin && rankingDirty && (
        <RankSaveButton
          variant="contained"
          color="secondary"
          onClick={() => {
            dispatch(updateCoachRanking(rankMapRef.current))
            setRankingDirty(false)
          }}>
          Save ranking
        </RankSaveButton>
      )}
    </List>
  )
}

export const ExploreAuthorList = () => {
  const dispatch = useDispatch()

  const activeAuthor = useSelector(
    (state: RootState) => state.explore.activeAuthor
  )

  return (
    <AuthorList
      activeAuthor={activeAuthor}
      onAuthorPressed={(author) => dispatch(setActiveAuthor(author))}
    />
  )
}
