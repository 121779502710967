import {
  DeleteSweep,
  Publish,
  QueueMusic,
  Settings,
  Undo
} from "@mui/icons-material"
import CopyIcon from "@mui/icons-material/AssignmentReturned"
import CloneIcon from "@mui/icons-material/FileCopyOutlined"
import HelpIcon from "@mui/icons-material/HelpOutline"
import OpenIcon from "@mui/icons-material/OpenInNew"
import RefreshIcon from "@mui/icons-material/Refresh"
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
  Tooltip
} from "@mui/material"
import copyToClipboard from "copy-text-to-clipboard"
import { useState } from "react"
import { useDispatch } from "react-redux"

import { useGlobal } from "shared/hooks/use-global"

import { useStoryEditor } from "../hooks/use-story-editor"

export const HelpButton = (props) => (
  <Button
    variant="outlined"
    color="inherit"
    style={{
      justifyContent: "center",
      alignItems: "center",
      alignContent: "center"
    }}
    onClick={() => {
      alert(props.message)
    }}>
    <HelpIcon />
  </Button>
)

export const CopyAndOpenButtonGroup = () => {
  const { material, published, linkUrl } = useStoryEditor()

  const [openTooltip, setOpenTooltip] = useState(false)

  return (
    <Tooltip
      arrow
      open={openTooltip}
      placement="top"
      title={"Please publish the inquiry"}>
      <ButtonGroup
        color="primary"
        variant="outlined"
        onMouseOver={() => setOpenTooltip(!material.sid || !published)}
        onMouseLeave={() => setOpenTooltip(false)}>
        <Button
          title="Open the inquiry to test"
          disabled={!published}
          href={linkUrl}
          target="_blank">
          <OpenIcon />
        </Button>
      </ButtonGroup>
    </Tooltip>
  )
}

export const CommonSwitcher = (props) => {
  const { material, updateMaterial, isSaving: disabled } = useStoryEditor()

  const dispatch = useDispatch()

  if (!material) return null

  return (
    <FormControlLabel
      disabled={disabled}
      control={
        <Switch
          checked={material[props.attributeName] || false}
          onChange={(_, checked) =>
            dispatch(
              updateMaterial({
                id: material.id,
                payload: {
                  [props.attributeName]: checked
                }
              })
            )
          }
          color="primary"
          name={props.attributeName}
          inputProps={{ "aria-label": props.labelName + " checkbox" }}
        />
      }
      label={props.labelName}
    />
  )
}

export const FeaturesToggleGroup = () => {
  const [open, setOpen] = useState(false)
  const { isAdmin } = useGlobal()
  const { material } = useStoryEditor()

  if (!material) return null

  return (
    <>
      <Button
        variant="outlined"
        title="View and manage inquiry's settings"
        onClick={async () => {
          setOpen(true)
        }}>
        <Settings />
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <Button variant="contained" size="small" color="primary">
            <Settings />
          </Button>
          <span style={{ marginLeft: 16 }}>Settings</span>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <CommonSwitcher
                attributeName="promptShare"
                labelName="Prompt reply"
              />
            </ListItem>
            <ListItem>
              <CommonSwitcher attributeName="rating" labelName="Rating" />
            </ListItem>
            {isAdmin && (
              <>
                <ListItem>
                  <CommonSwitcher
                    attributeName="featured"
                    labelName="Featured"
                  />
                </ListItem>
                <ListItem>
                  <CommonSwitcher
                    attributeName="quickStart"
                    labelName="Quick start"
                  />
                </ListItem>
                <ListItem>
                  <CommonSwitcher attributeName="ftux" labelName="FTUX" />
                </ListItem>
                <ListItem>
                  <CommonSwitcher
                    attributeName="aiSummaryFlow"
                    labelName="Ai Summary Flow"
                  />
                </ListItem>
              </>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const AudioButtonGroup = () => {
  const { material } = useStoryEditor()
  const { transport } = useGlobal()

  const [open, setOpen] = useState(false)
  const [materialAudioList, setMaterialAudioList] = useState<
    Array<{
      name: string
      audio: string
    }>
  >()

  if (!material) return null

  return (
    <>
      <Button
        variant="outlined"
        title="View and manage your audio records"
        onClick={async () => {
          const resp = await transport.get(`material/audio?id=${material.id}`)
          setMaterialAudioList(resp.data)
          setOpen(true)
        }}>
        <QueueMusic />
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Uploaded and recorded audio</DialogTitle>
        <DialogContent>
          <List
            style={{
              maxHeight: 400,
              overflow: "auto"
            }}>
            {materialAudioList?.map(({ name, audio }) => (
              <ListItem key={audio}>
                <ListItemText
                  primary={name}
                  style={{
                    maxWidth: 400,
                    paddingRight: 20
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    title="Copy audio to your clipboard"
                    onClick={(e) => {
                      copyToClipboard(`audio: ${audio}`, {
                        target: e.currentTarget
                      })
                    }}>
                    <CopyIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export const DeleteButton = ({ onPress, disabled = false }) => (
  <Button
    variant="outlined"
    color="error"
    disabled={disabled}
    startIcon={<DeleteSweep />}
    onClick={onPress}>
    Delete
  </Button>
)

export const PublishButtonGroup = () => {
  const { transport } = useGlobal()
  const dispatch = useDispatch()
  const {
    material,
    story,
    published,
    isReadyToPublish,
    dirtyItemList,
    updateMaterial,
    publishMaterial
  } = useStoryEditor()

  const { hasAnItem, hasName, noDirtyItem } = isReadyToPublish
  const [openTooltip, setOpenTooltip] = useState(false)

  const isReady = hasAnItem && hasName && noDirtyItem

  const tooltip = !hasName
    ? "Please fill out and save the inquiry title"
    : !hasAnItem
    ? "Please add a question"
    : !noDirtyItem
    ? `Please fill out and save: ${dirtyItemList}`
    : ""

  return (
    <Tooltip arrow open={openTooltip} placement="top-end" title={tooltip}>
      <ButtonGroup
        variant="contained"
        onMouseOver={() => setOpenTooltip(!isReady)}
        onMouseLeave={() => setOpenTooltip(false)}>
        {published && !story.live && (
          <Button
            title="Unpublish"
            startIcon={<Undo />}
            onClick={async () => {
              dispatch(
                updateMaterial({
                  id: material.id,
                  promise: transport.delete("material/publish", {
                    params: {
                      id: material.id
                    }
                  })
                })
              )
            }}>
            Unpublish
          </Button>
        )}

        <Button
          title="Publish the current inquiry draft"
          color={story.published && !!published ? "inherit" : "primary"}
          startIcon={<Publish />}
          disabled={!isReady}
          onClick={publishMaterial}>
          {published ? "Re-publish" : "Publish"}
        </Button>
      </ButtonGroup>
    </Tooltip>
  )
}

export const RefreshAndCloneButtonGroup = ({ onClone = () => null }) => {
  const { isSaving, revalidateStory } = useStoryEditor()

  return (
    <ButtonGroup color="primary" variant="outlined">
      <Button
        title="Refresh the content"
        disabled={isSaving}
        onClick={() => revalidateStory()}>
        <RefreshIcon />
      </Button>
      <Button
        title="Make a copy of this inquiry in your editor"
        disabled={isSaving}
        onClick={onClone}>
        <CloneIcon />
      </Button>
    </ButtonGroup>
  )
}
