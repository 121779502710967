import type { SelectProps } from "@mui/material"
import { FormControl, InputLabel, Select } from "@mui/material"
import type { FC } from "react"

import { useStoryEditor } from "../hooks/use-story-editor"
import { useStoryItemEditor } from "../hooks/use-story-item-editor"

export const StoryEditorGotoSelect: FC<
  SelectProps & {
    defaultOption?: string
    meta?: boolean
  }
> = ({ style, meta, defaultOption, ...props }) => {
  const { itemHeaderMap, getValidItemHeader } = useStoryEditor()
  const storyItemEditor = useStoryItemEditor()
  return (
    <FormControl focused style={style}>
      <InputLabel
        style={{
          position: "absolute",
          top: -6,
          left: 8,
          background: "white",
          zIndex: 10,
          padding: "0 8px"
        }}>
        {props.label}
      </InputLabel>

      <Select
        variant="outlined"
        native
        style={{
          height: 40
        }}
        {...props}>
        <option value="">{defaultOption || "None"}</option>
        {getValidItemHeader(meta ? null : storyItemEditor?.item?.id).map(
          (k) => (
            <option key={k} value={itemHeaderMap[k]}>
              {itemHeaderMap[k]}
            </option>
          )
        )}
      </Select>
    </FormControl>
  )
}
