import { useAuth0 } from "@auth0/auth0-react"
import { Button, styled, Toolbar } from "@mui/material"
import type { FC } from "react"
import { Link, useLocation } from "react-router-dom"

import { useGlobal } from "shared/hooks/use-global"

export type NavLink = {
  title: string
  link: string
}

const NavBarContainer = styled("div")`
  display: flex;
  position: fixed;
  top: 0px;
  width: 100%;
  background: #444;
  z-index: 100;
  overflow-x: auto;
  overflow-y: auto;
`

const NavLinkButton = styled(Link)`
  padding-right: 32px;
`

const NavBar: FC<{ navLinks: Array<NavLink>; adminLink }> = ({
  navLinks,
  adminLink
}) => {
  const { logout } = useAuth0()
  const { isAdmin } = useGlobal()
  const location = useLocation()

  return (
    <NavBarContainer>
      <Toolbar variant="dense">
        {navLinks.map(({ title, link }, idx) => (
          <NavLinkButton
            key={idx}
            style={
              location.pathname === link
                ? {
                    textDecoration: "underline",
                    fontWeight: "bold",
                    color: "white"
                  }
                : {
                    textDecoration: "none",
                    color: "gray",
                    fontWeight: "normal"
                  }
            }
            to={link}>
            {title}
          </NavLinkButton>
        ))}
        {adminLink && isAdmin && (
          <NavLinkButton
            style={
              location.pathname === adminLink.link
                ? {
                    textDecoration: "underline",
                    fontWeight: "bold",
                    color: "white"
                  }
                : {
                    textDecoration: "none",
                    color: "gray",
                    fontWeight: "normal"
                  }
            }
            to={adminLink.link}>
            {adminLink.title}
          </NavLinkButton>
        )}
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => {
            logout({ returnTo: window.location.origin })
          }}>
          Logout
        </Button>
      </Toolbar>
    </NavBarContainer>
  )
}

export default NavBar
