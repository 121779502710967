import type { AxiosRequestConfig } from "axios"
import type { Key } from "swr"

import { Transport } from "@considr-it/ponder-shared"

import { useGlobal } from "./use-global"

export const usePonderServerSWR = <Data = any, Error = any>(
  path: Key,
  config?: Transport.Config<Data, Error>,
  transportConfig?: AxiosRequestConfig
) => {
  const { transport, account } = useGlobal()

  return Transport.useTransportSWR<Data, Error>(
    transport,
    !!account ? path : null,
    config,
    transportConfig
  )
}
