import { useAuth0 } from "@auth0/auth0-react"
import { css } from "@emotion/react"
import { Button, TextField } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { isValidPhoneNumber } from "libphonenumber-js"
import React, { useState } from "react"

import { useGlobal } from "shared/hooks/use-global"

import { PhoneInput } from "features/clientele/components/phone-input"

export const ProfilePImageContainer = styled(CardMedia)`
  max-width: 80px;
  max-height: 80px;
  width: 10vw;
  height: 10vw;
  margin-left: 32px;
  border-radius: 100%;
`

export const ProfileContent = styled(CardContent)`
  flex: 1 0 auto;
`

export const ProfileControls = styled("div")`
  display: flex;
  align-items: center;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`

export const RightBioContainer = styled("div")`
  margin-left: 32px;
  width: 70%;
  overflow: visible;
`

export const LeftBioContainer = styled("div")`
  width: 30%;
`

export const containerCss = css`
  display: flex;
  padding: 1em;
`

export const ActionContainer = styled("div")`
  ${containerCss};
`

export const CardContainer = styled(Card)`
  ${containerCss};
  width: 100%;
  padding: 16px;
  justify-content: space-between;
`

export const DetailContainer = styled("div")`
  display: flex;
  flex-direction: column;
`

const ProfilePage = () => {
  const { user } = useAuth0()
  const { pingData, account, updateAccount } = useGlobal()

  const [bio, setBio] = useState(account.bio)
  const [shortBio, setShortBio] = useState(account.profile.shortBio)
  const [title, setTitle] = useState(account.profile.title)
  const [phone, setPhone] = useState(account.phone)

  return (
    <div className="root-content">
      <div className="content">
        <CardContainer>
          <DetailContainer>
            <ProfileContent>
              <Typography component="h5" variant="h5">
                {account.profile.name}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {account.role.toUpperCase()}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {account.mainEmail}
              </Typography>
            </ProfileContent>
          </DetailContainer>

          <ProfilePImageContainer
            image={account.profile.imageUrl}
            title="User avatar"
          />
        </CardContainer>

        <ActionContainer>
          <Button
            variant="contained"
            href={`${pingData?.appOrigin}/coach/${account.id}`}
            target="_blank">
            Open Coach Page
          </Button>
          <div style={{ width: 16 }} />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const payload = {
                bio,
                phone,
                profile: {
                  ...account.profile,
                  shortBio,
                  title,
                  // givenName: user.given_name,
                  // familyName: user.family_name,
                  // name: `${user.given_name} ${user.family_name}`,
                  imageUrl: user.picture
                }
              }

              if (!phone || !isValidPhoneNumber(phone)) {
                payload.phone = ""
              }

              updateAccount(payload)
            }}>
            Save
          </Button>
        </ActionContainer>

        <CardContainer>
          <LeftBioContainer>
            <TextField
              fullWidth
              aria-label="Title"
              placeholder="Experimental Coach"
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <div style={{ height: 24 }} />
            <PhoneInput fullWidth value={phone} onUpdate={setPhone} />

            <div style={{ height: 24 }} />

            <TextField
              rows={3}
              fullWidth
              multiline
              variant="outlined"
              label="Short Biography"
              aria-label="Short Bio"
              placeholder="An introduction, a briefing"
              value={shortBio}
              onChange={(e) => setShortBio(e.target.value)}
            />
          </LeftBioContainer>
          <RightBioContainer>
            <TextField
              rows={10}
              fullWidth
              multiline
              variant="outlined"
              label="Full Biography"
              aria-label="Bio"
              placeholder="A clear description about you and your service for prospective clients and the coaching community"
              value={bio}
              onChange={(e) => setBio(e.target.value)}
            />
          </RightBioContainer>
        </CardContainer>
      </div>
    </div>
  )
}

export default {
  Page: ProfilePage,
  link: "/profile",
  name: "Profile"
} as IPage
