import { Edit } from "@mui/icons-material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material"
import type { FC } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"

import type { CoachClientEdge, User } from "@considr-it/ponder-entities"

import { useGlobal } from "shared/hooks/use-global"

import { verifyClient } from "../redux/clientele-slice"
import { PhoneInput } from "./phone-input"

type EditClientButtonProps = {
  coachClientEdge?: CoachClientEdge
}

export const EditClientButton: FC<EditClientButtonProps> = ({
  coachClientEdge,
  ...props
}) => {
  const client = coachClientEdge.client as User

  const { transport } = useGlobal()
  const dispatch = useDispatch()

  const [open, setOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [phone, setPhone] = useState<string>(coachClientEdge.clientPhone || "")

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        title="Open edit dialog"
        onClick={() => setOpen(true)}
        {...props}>
        <Edit />
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          {`Update ${client?.profile?.name || "client"}'s detail`}
        </DialogTitle>
        <DialogContent>
          <PhoneInput
            required
            value={phone}
            onUpdate={setPhone}
            size="small"
            placeholder="To send sms invitation"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={isSaving}
            onClick={async () => {
              setIsSaving(true)
              await transport.patch("coach/client", {
                id: coachClientEdge.id,
                payload: {
                  clientPhone: phone
                }
              })

              dispatch(
                verifyClient({
                  ...coachClientEdge,
                  protocol: "sms"
                })
              )

              setOpen(false)
              setIsSaving(false)
            }}
            color="primary">
            Save
          </Button>
          <Button
            variant="contained"
            disabled={isSaving}
            onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
