import styled from "@emotion/styled"
import { Card } from "@mui/material"

import { useWide } from "shared/hooks/use-screen-size"

import { StoryList } from "features/query/story-list"
import { StoryPlayground } from "features/query/story-playground"
import { StoryViewer } from "features/query/story-viewer"

const LLMContainer = styled(Card)<{ desktop?: boolean }>`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const ResponsiveLLMContainer = (props) => {
  const isWideScreen = useWide()

  return (
    <LLMContainer
      style={{
        flexDirection: isWideScreen ? "row" : "column"
      }}
      {...props}
    />
  )
}

const Query = () => {
  return (
    <div className="root-content">
      <div className="content">
        <ResponsiveLLMContainer>
          <StoryList />
          <StoryViewer />
          <StoryPlayground />
        </ResponsiveLLMContainer>
      </div>
    </div>
  )
}

export default {
  Page: Query,
  link: "/query",
  name: "Query"
} as IPage
