import { Save } from "@mui/icons-material"
import type { TextFieldProps } from "@mui/material"
import {
  Button,
  ButtonGroup,
  FormControlLabel,
  InputAdornment,
  styled,
  Switch,
  TextField
} from "@mui/material"
import { useFormik } from "formik"

import { StoryType } from "@considr-it/ponder-enums"
import { Validator } from "@considr-it/ponder-shared"

import { useStoryEditor } from "../hooks/use-story-editor"
import { HelpButton } from "./edit-panel-group"
import { StoryEditorGotoSelect } from "./story-editor-goto-select"
import { StoryEditorPromptsSelect } from "./story-editor-prompt-select"
import { StoryEditorTypeSelect } from "./story-editor-type-select"

const StoryEditorHeaderForm = styled("form")`
  display: flex;
  flex-wrap: wrap;
  padding: 4px;
  background: white;
`

const MainField = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 240px;
  & > div,
  & > button {
    margin: ${({ theme }) => theme.spacing(1)};
  }
`
const SubField = styled("div")`
  display: flex;
  flex-direction: row;
  & > div,
  & > button {
    margin: ${({ theme }) => theme.spacing(1)};
  }
`

export const StoryEditorHeader = () => {
  const {
    readOnly,
    story,
    isSaving,
    save,
    toggleExpandedHeader,
    showExpandedHeader
  } = useStoryEditor()

  const headerForm = useFormik({
    initialValues: {
      name: story.name || "",
      tagline: story.tagline || "",
      bullets: story.bullets[0] ? story.bullets : [],
      action: story.meta?.action || "",
      duration: story.meta?.duration || "",
      type: story.type || StoryType.Questioning,
      defaultPrompt: story.defaultPrompt
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema: Validator.Story.header,
    onSubmit: async (newData) => {
      const { name, bullets, tagline, type, defaultPrompt, ...meta } = newData
      if (headerForm.dirty) {
        // if (meta.duration === story.meta?.duration) {
        //   meta.duration = undefined
        // }
        save({
          name,
          tagline,
          bullets,
          hasBullets: bullets.length > 0 ? true : false,
          type,
          defaultPrompt,
          meta
        })
      }
    }
  })

  const commonForm: TextFieldProps = readOnly
    ? {}
    : {
        onChange: headerForm.handleChange
      }

  const bulletForm: TextFieldProps = {
    variant: "standard",
    size: "small",
    InputProps: {
      startAdornment: <InputAdornment position="start">+</InputAdornment>
    }
  }

  return (
    <StoryEditorHeaderForm onSubmit={headerForm.handleSubmit}>
      <MainField
        style={{
          flexGrow: 12
        }}>
        <TextField
          variant="outlined"
          label="Inquiry title"
          name="name"
          size="small"
          required
          value={headerForm.values.name}
          {...commonForm}
        />
        {showExpandedHeader && (
          <MainField
            style={{
              flexGrow: 12
            }}>
            <TextField
              variant="outlined"
              label="Tagline"
              name="tagline"
              size="small"
              value={headerForm.values.tagline}
              {...commonForm}
            />
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                border: "1px solid gray",
                borderRadius: 4,
                padding: 8
              }}>
              <p
                style={{
                  fontSize: 12,
                  position: "absolute",
                  top: -20,
                  left: 8,
                  background: "white",
                  padding: "0 4px"
                }}>
                Descriptive Bullets
              </p>
              <TextField
                name="bullets[0]"
                value={headerForm.values.bullets[0]}
                {...bulletForm}
                {...commonForm}
              />
              <TextField
                name="bullets[1]"
                value={headerForm.values.bullets[1]}
                {...bulletForm}
                {...commonForm}
              />
              <TextField
                name="bullets[2]"
                value={headerForm.values.bullets[2]}
                error={
                  headerForm.touched.bullets &&
                  Boolean(headerForm.errors.bullets)
                }
                helperText={
                  headerForm.touched.bullets && headerForm.errors.bullets
                }
                {...bulletForm}
                {...commonForm}
              />
            </div>
          </MainField>
        )}
      </MainField>

      <MainField
        style={{
          flexGrow: 2
        }}>
        <FormControlLabel
          control={
            <Switch
              title="Advanced Options"
              checked={showExpandedHeader}
              onClick={toggleExpandedHeader}></Switch>
          }
          style={{ fontWeight: "bold", marginLeft: "8px" }}
          label="Advanced"
        />
        {showExpandedHeader && (
          <>
            <SubField>
              <StoryEditorGotoSelect
                label="Action"
                value={headerForm.values.action}
                name="action"
                disabled={readOnly}
                onChange={headerForm.handleChange}
                style={{ width: "100%" }}
                meta
              />
              <HelpButton
                message={
                  "Allow users to send an email reminder in regards to a particular question"
                }
              />
            </SubField>
            <SubField>
              <StoryEditorTypeSelect
                label="Type"
                value={headerForm.values.type}
                name="type"
                disabled={readOnly}
                onChange={headerForm.handleChange}
                style={{ width: "100%" }}
              />
            </SubField>
            {headerForm.values.type === StoryType.Writing && (
              <SubField>
                <StoryEditorPromptsSelect
                  label="Prompt"
                  value={headerForm.values.defaultPrompt}
                  name="defaultPrompt"
                  disabled={readOnly}
                  onChange={headerForm.handleChange}
                  style={{ width: "100%" }}
                />
              </SubField>
            )}
          </>
        )}

        <ButtonGroup>
          {headerForm.dirty && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              startIcon={<Save />}
              disabled={isSaving}>
              Save
            </Button>
          )}
        </ButtonGroup>
      </MainField>
    </StoryEditorHeaderForm>
  )
}
