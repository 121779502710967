import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type {
  Material,
  Story,
  StoryItem,
  StoryLogItem,
  StoryLogShareEdge,
  User
} from "@considr-it/ponder-entities"

import type { RemoteState } from "shared/redux/loading"

import { generateMaterialThunk } from "features/query/redux/material-thunk"

interface queryState extends RemoteState {
  activeMaterial: Material
  materials: Array<Material>
  activeShareEdges: Array<StoryLogShareEdge>
  activeShareEdgeLogToView: Partial<StoryLogShareEdge>
  activeStory: Story
  activeStoryItem: StoryItem
  activeStoryLogItem: StoryLogItem
  checkedUsersForQuery: Array<Partial<User>>
  checkedTranscriptsForQuery: Array<string>
  queryResult: string
}

const initialState: queryState = {
  activeMaterial: null,
  materials: null,
  activeShareEdges: null,
  activeShareEdgeLogToView: null,
  activeStory: null,
  activeStoryItem: null,
  activeStoryLogItem: null,
  checkedUsersForQuery: [],
  checkedTranscriptsForQuery: [],
  queryResult: null,
  isLoading: false,
  error: null
}

const query = createSlice({
  name: "Query",
  initialState,
  reducers: {
    setActiveMaterial: (
      state: queryState,
      { payload }: PayloadAction<Material>
    ) => {
      state.activeMaterial = payload
    },
    setMaterials: (
      state: queryState,
      { payload }: PayloadAction<Array<Material>>
    ) => {
      state.materials = payload
    },
    setActiveShareEdges: (
      state: queryState,
      { payload }: PayloadAction<Array<StoryLogShareEdge>>
    ) => {
      state.activeShareEdges = payload
    },
    setActiveStory: (state: queryState, { payload }: PayloadAction<Story>) => {
      state.activeStory = payload
    },
    setActiveStoryItem: (
      state: queryState,
      { payload }: PayloadAction<StoryItem>
    ) => {
      state.activeStoryItem = payload
    },
    setActiveStoryLogItem: (
      state: queryState,
      { payload }: PayloadAction<StoryLogItem>
    ) => {
      state.activeStoryLogItem = payload
    },
    setActiveShareEdgeLogToView: (
      state: queryState,
      { payload }: PayloadAction<Partial<StoryLogShareEdge>>
    ) => {
      state.activeShareEdgeLogToView = payload
    },
    setCheckedUsersForQuery: (
      state: queryState,
      { payload }: PayloadAction<Array<Partial<User>>>
    ) => {
      state.checkedUsersForQuery = payload
    },
    setCheckedTranscriptsForQuery: (
      state: queryState,
      { payload }: PayloadAction<Array<string>>
    ) => {
      state.checkedTranscriptsForQuery = payload
    },
    setQueryResult: (state: queryState, { payload }: PayloadAction<string>) => {
      state.queryResult = payload
    },
    setIsLoading: (state: queryState, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    }
  }
})

export const {
  setActiveMaterial,
  setMaterials,
  setActiveShareEdges,
  setActiveShareEdgeLogToView,
  setActiveStory,
  setActiveStoryItem,
  setActiveStoryLogItem,
  setCheckedUsersForQuery,
  setCheckedTranscriptsForQuery,
  setIsLoading
} = query.actions

export const queryReducer = query.reducer

export const {
  fetchMaterials,
  fetchShareEdges,
  fetchStory,
  fetchStoryLogItem,
  sendQuery,
  addTranscriptToQuery,
  clearActiveSelections
} = generateMaterialThunk(query.actions)
