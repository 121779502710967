import type { RootState } from "core/reducer"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import { useDispatch } from "react-redux"

import type { Story } from "@considr-it/ponder-entities"

import { useGlobal } from "shared/hooks/use-global"

import { updateMaterial as editorUpdateMaterial } from "features/editor/redux/editor-slice"

import { useStoryViewer } from "./use-story-viewer"

export const useStoryEditorProvider = ({
  updateMaterial = editorUpdateMaterial,
  onSelector = (state: RootState) => state.editor,
  readOnly = false
}) => {
  const { transport } = useGlobal()
  const dispatch = useDispatch()
  const {
    material,
    story,
    isSaving: isMaterialSaving,
    isLoading,
    published,
    revalidateStory
  } = useStoryViewer({
    onSelector
  })

  const [isStorySaving, setIsStorySaving] = useState(false)
  const [dirtyItemMap, setDirtyItemMap] = useState({})
  const [needAudioMap, setNeedAudioMap] = useState({})
  const [itemHeaderMap, setItemHeaderMap] = useState({})
  const [showExpandedHeader, setShowExpandedHeader] = useState(false)

  useEffect(() => {
    if (!!story && story.material === material?.id) return
    if (!material) return

    revalidateStory()
  }, [material, story, dispatch, revalidateStory])

  const save = async (payload: Partial<Story>) => {
    if (readOnly) return
    if (isMaterialSaving || isStorySaving) return

    setIsStorySaving(true)

    await transport.patch("story", {
      id: story.id,
      payload
    })
    await revalidateStory()

    setIsStorySaving(false)
  }

  const createItem = async (index: number, storyItemType: string) => {
    setIsStorySaving(true)

    await transport.post("story/item", {
      storyId: story.id,
      index,
      storyItemType
    })
    await revalidateStory()

    setIsStorySaving(false)
  }

  const deleteItem = async (id: string) => {
    setIsStorySaving(true)

    await transport.delete("story/item", {
      params: { id }
    })
    await revalidateStory()

    setIsStorySaving(false)
  }

  const reorderItem = async (from: number, to: number) => {
    setIsStorySaving(true)
    await transport.patch("story/reorder", {
      id: story.id,
      from,
      to
    })
    await revalidateStory()

    setIsStorySaving(false)
  }

  const toggleExpandedHeader = () => {
    setShowExpandedHeader(!showExpandedHeader)
  }

  const publishMaterial = () => {
    if (!!needAudioList) {
      if (
        !window.confirm(
          `Please confirm each question has the appropriate audio files before publishing. Are you sure you want to publish?`
        )
      )
        return null
    }

    const publishPromise = transport
      .post("material/publish", {
        id: material.id
      })
      .then((d) => {
        revalidateStory()
        return d
      })

    dispatch(
      updateMaterial({
        id: material.id,
        promise: publishPromise
      })
    )

    return publishPromise
  }

  const isReadyToPublish = useMemo(
    () => ({
      hasName: !!story?.name,
      hasAnItem: story?.items.length > 0,
      noDirtyItem: !Object.values(dirtyItemMap).some((d) => !!d)
    }),
    [story?.name, story?.items.length, dirtyItemMap]
  )

  const dirtyItemList = useMemo(
    () =>
      Object.values(dirtyItemMap)
        .filter((d) => !!d)
        .join(", "),
    [dirtyItemMap]
  )

  const needAudioList = useMemo(
    () =>
      Object.values(needAudioMap)
        .filter((d) => !!d)
        .join(", "),
    [needAudioMap]
  )

  const getValidItemHeader = useCallback(
    (currentHeader: string) =>
      Object.keys(itemHeaderMap).filter(
        (i) => !!itemHeaderMap[i] && i !== currentHeader
      ),
    [itemHeaderMap]
  )

  const [linkUrl, setLinkUrl] = useState<string | null>(null)

  return {
    readOnly,
    material,
    story,
    isSaving: isStorySaving || isStorySaving,
    isLoading,
    published,
    revalidateStory,
    save,
    isReadyToPublish,
    createItem,
    deleteItem,
    reorderItem,
    dirtyItemList,
    setDirtyItemMap,
    needAudioList,
    setNeedAudioMap,
    itemHeaderMap,
    setItemHeaderMap,
    getValidItemHeader,
    toggleExpandedHeader,
    showExpandedHeader,
    updateMaterial,
    publishMaterial,
    linkUrl,
    setLinkUrl
  }
}

export type StoryEditorHook = ReturnType<typeof useStoryEditorProvider>

export const StoryEditorContext = createContext<StoryEditorHook>(null)

export const useStoryEditor = () => useContext(StoryEditorContext)
