import { ClienteleAuthorList } from "features/clientele/components/author-list"
import { ClientList } from "features/clientele/components/client-list"

import { ResponsiveEditorContainer } from "./editor"

const MetricPage = () => {
  return (
    <div className="root-content">
      <div className="content">
        <ResponsiveEditorContainer>
          <ClienteleAuthorList />
          <ClientList />
        </ResponsiveEditorContainer>
      </div>
    </div>
  )
}

export default {
  Page: MetricPage,
  link: "/metric",
  name: "Metric"
} as IPage
