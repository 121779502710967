import styled, { css } from "@emotion/native"

import { Color } from "../layouts/color"

const commonTextStyle = css(`
  margin: 0;
`)

export const H1 = styled.Text`
  ${commonTextStyle};
  font-family: MetropolisBold;
  font-style: normal;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: ${Color.text.black};
`

export const H2 = styled.Text`
  ${commonTextStyle};
  font-family: MetropolisBold;
  font-size: 26px;
  line-height: 29px;
  color: ${Color.text.black};
`

export const H3 = styled.Text`
  ${commonTextStyle};
  font-family: Metropolis;

  font-size: 12px;
  line-height: 20px;

  color: ${Color.text.gray};
`

export const H4 = styled.Text`
  ${commonTextStyle};
  font-family: MetropolisBold;

  font-size: 18px;
  line-height: 21px;
  color: ${Color.text.white};
`

export const P = styled.Text`
  ${commonTextStyle};
  font-family: Metropolis;
  font-size: 16px;

  line-height: 25px;

  text-align: center;

  color: ${Color.text.gray};
`

export const CenterHeading = styled(H2)`
  text-align: center;
`
