import { useEffect } from "react"
import type { RouteProps } from "react-router-dom"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import type { PingResponse } from "@considr-it/ponder-entities"
import { Transport } from "@considr-it/ponder-shared"

import NavBar from "shared/components/nav-bar"
import {
  GlobalAuthProvider,
  GlobalProvider,
  publicTransport
} from "shared/hooks/use-global"

import Editor from "pages/editor"
import Explore from "pages/explore"
import Home from "pages/home"
import Metric from "pages/metric"
import Profile from "pages/profile"
import LLM from "pages/query"

import Admin from "./pages/admin"

const pages = [Profile, Metric, Editor, Explore, LLM]

const adminLink = {
  title: Admin.name,
  link: Admin.link
}

const routes = [
  // Add new route to be registered here
  ...pages.map((p) => ({
    path: p.link,
    component: p.Page
  })),
  {
    path: Home.link,
    component: Home.Page,
    exact: true
  },
  {
    path: "/admin",
    component: Admin.Page
  },
  {
    component: function NotFound() {
      return <h1>Page not found</h1>
    }
  }
]

// Add route to show on nav bar here
const navLinks = [Home, ...pages].map((p) => ({
  title: p.name,
  link: p.link
}))

export const App = () => {
  useEffect(() => {
    const currentLocation = window.location.href

    if (currentLocation.includes("ponder.coach")) {
      window.location.href = currentLocation.replace(
        "ponder.coach",
        "bestoried.io"
      )
    }
  }, [])

  const { data: pingData } = Transport.useTransportSWR<PingResponse>(
    publicTransport,
    "ping"
  )
  return (
    <GlobalAuthProvider pingData={pingData}>
      <GlobalProvider pingData={pingData}>
        <Router>
          <NavBar adminLink={adminLink} navLinks={navLinks} />
          <Switch>
            {routes.map((route: RouteProps, i: number) => (
              <Route key={i} {...route} />
            ))}
          </Switch>
        </Router>
      </GlobalProvider>
    </GlobalAuthProvider>
  )
}
