import { useAuth0 } from "@auth0/auth0-react"
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  styled,
  TextField,
  Typography
} from "@mui/material"
import { useFormik } from "formik"
import { useState } from "react"
import emoji from "react-easy-emoji"

import { Validator } from "@considr-it/ponder-shared"

import { useGlobal } from "shared/hooks/use-global"

const ProfileForm = styled("form")`
  display: flex;
  flex-direction: column;
  & > * {
    margin: ${({ theme }) => theme.spacing(1)};
  }
`

const ConfirmProfile = () => {
  const { user, logout } = useAuth0()
  const { transport, revalidateAccount } = useGlobal()
  const { given_name, family_name, email: mainEmail, picture: imageUrl } = user
  const [submitting, setSubmitting] = useState(false)
  const [checked, setChecked] = useState(false)

  const profileForm = useFormik({
    initialValues: {
      givenName: given_name || "",
      familyName: family_name || ""
    },
    validationSchema: Validator.Profile.basic,
    onSubmit: async ({ givenName, familyName }) => {
      if (submitting) return

      setSubmitting(true)
      await transport.post("auth", {
        mainEmail,
        profile: {
          imageUrl,
          givenName,
          familyName,
          name: `${givenName} ${familyName}`
        }
      })

      revalidateAccount()
    }
  })

  return (
    <Card>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center">
          <Typography color="textSecondary">
            <i>{"Let's complete your profile"}</i>
          </Typography>
          <Typography color="primary">
            <b>{mainEmail}</b>
          </Typography>
        </Grid>
        <ProfileForm onSubmit={profileForm.handleSubmit}>
          <TextField
            variant="outlined"
            label="First name"
            name="givenName"
            onChange={profileForm.handleChange}
            error={
              profileForm.touched.givenName &&
              Boolean(profileForm.errors.givenName)
            }
            helperText={
              profileForm.touched.givenName && profileForm.errors.givenName
            }
          />
          <TextField
            variant="outlined"
            label="Last name"
            name="familyName"
            onChange={profileForm.handleChange}
            error={
              profileForm.touched.familyName &&
              Boolean(profileForm.errors.familyName)
            }
            helperText={
              profileForm.touched.familyName && profileForm.errors.familyName
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                color="primary"
                required
              />
            }
            label={
              <>
                {`I have read and agreed to the `}
                <Link
                  href="https://www.bestoried.ai/terms-of-service"
                  color="primary"
                  target="_blank"
                  rel="noopener">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link
                  color="primary"
                  href="https://www.bestoried.ai/privacy-policy"
                  target="_blank"
                  rel="noopener">
                  Privacy Policy
                </Link>
                {" presented by Storied"}
              </>
            }
          />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!checked || !profileForm.isValid || submitting}>
            Confirm
          </Button>
          <hr />
          <Button
            color="error"
            variant="contained"
            fullWidth
            disabled={submitting}
            onClick={() => logout({ localOnly: true })}>
            Logout
          </Button>
        </ProfileForm>
      </CardContent>
    </Card>
  )
}

const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  return (
    <div className="content">
      <h2>Welcome to Storied Admin Portal {emoji("👋")} </h2>
      {isAuthenticated ? (
        <ConfirmProfile />
      ) : (
        <Button color="primary" variant="contained" onClick={loginWithRedirect}>
          Please Sign in
        </Button>
      )}
    </div>
  )
}

export default {
  Page: LoginPage,
  name: "Login",
  link: "/login"
} as IPage
