import styled from "@emotion/styled"
import type { RootState } from "core/reducer"
import { useDispatch, useSelector } from "react-redux"

import { useGlobal } from "shared/hooks/use-global"
import { useWideVisible } from "shared/hooks/use-screen-size"

import { QuickAndDirtyAccordionButton } from "features/editor/components/accordion"
import { AuthorList } from "features/explore/components/author-list"

import { setActiveAuthor } from "../redux/clientele-slice"

const AuthorListContainer = styled.div`
  height: 100%;
  overflow: auto;
`

export const ClienteleAuthorList = () => {
  const dispatch = useDispatch()

  const [isHidden, setIsHidden] = useWideVisible()
  const { isAdmin } = useGlobal()
  const activeAuthor = useSelector(
    (state: RootState) => state.clientele.activeAuthor
  )

  if (!isAdmin) return null

  return (
    <>
      <AuthorListContainer style={{ flexBasis: isHidden ? 0 : 240 }}>
        <AuthorList
          activeAuthor={activeAuthor}
          onAuthorPressed={(author) => dispatch(setActiveAuthor(author))}
        />
      </AuthorListContainer>
      <QuickAndDirtyAccordionButton
        isHidden={isHidden}
        onToggle={() => setIsHidden((s) => !s)}
      />
    </>
  )
}
