const bg = {
  orange: "rgba(250,129,82,1.0)",
  orange2: "rgba(247,221,210,1.0)",
  orange3: "rgba(254,223,212,1.0)",
  orange4: "rgba(248,97,54,1.0)",
  orange5: "rgba(244,68,34,1.0)",
  blue: "rgba(57,107,154,1.0)",
  blue2: "rgba(57,107,154,0.25)",
  blue3: "rgba(157,160,189,1.0)",
  blue4: "rgba(65,100,133,1.0)",
  blue5: "rgb(40, 69, 101)",
  purple: "rgba(102,109,158,1.0)",
  green: "rgba(121,158,102,1.0)",
  green2: "rgba(171,175,118,1.0)",
  green3: "rgba(212, 220, 203, 1)",
  turquoise: "rgba(102,158,157,1.0)",
  gray: "rgba(232,228,221,1.0)",
  gray2: "rgba(243,232,225,1.0)",
  gray3: "rgba(249,248,246,1.0)",
  gray4: "rgba(244,241,238,1.0)",
  white: "rgba(255,255,255,1.0)",
  white2: "rgba(248, 248, 248, 0.2)",
  white3: "rgba(245,245,245,1.0)",
  camel: "rgba(195,163,143,1.0)"
}

const bgShadow = {
  white: "rgba(246,243,243,1.0)",
  gray: "rgba(249,239,233,1.0)",
  darkgray: "rgba(124, 124, 124, 0.24)",
  orange: "rgba(249,113,68,1.0)",
  orange2: "rgba(249,242,238,1.0)",
  orange2transparent: "rgba(249,242,238, .6)",
  orange3: "rgba(250,129,82,0.1)",
  blue: "rgba(238,242,249,1.0)",

  red: "rgba(159, 34, 4, 0.677693)"
}

export const Color = {
  transparent: "rgba(255,255,255,0)",

  text: {
    black: "rgba(45,35,24,1.0)",
    gray: "rgba(166,163,156,1.0)",
    gray2: "rgba(70,75,87,1.0)",
    gray3: "rgba(247,240,236,1.0)",
    white: "rgba(248,243,240,1.0)",
    white2: "rgba(248,243,240,0.8)"
  },
  border: {
    gray: "rgba(166,163,156,0.25)"
  },
  bg,
  bgShadow,
  linearGradient: {
    whiteOrange: `linear-gradient(180deg, ${bg.white3} 0%, ${bgShadow.orange2} 100%)`,
    whiteBlue: `linear-gradient(180deg, ${bg.white3} 0%, ${bgShadow.blue}100%)`,
    orange: `linear-gradient(180deg, ${bg.orange} 0%, ${bg.orange} 100%)`,
    blue: `linear-gradient(180deg, ${bg.blue} 0%, ${bg.blue} 100%)`
  }
}
