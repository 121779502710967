import {
  CloudUpload,
  HourglassEmpty,
  PauseCircleOutline,
  PlayCircleOutline
} from "@mui/icons-material"
import RecordIcon from "@mui/icons-material/Mic"
import SubtitlesIcon from "@mui/icons-material/Subtitles"
import { Button, ButtonGroup } from "@mui/material"
import type { ChangeEvent, FC } from "react"
import { useRef, useState } from "react"

import { AudioModel } from "@considr-it/ponder-enums"
import { useCore } from "@considr-it/ponder-shared"

import { useAudioUpload } from "../hooks/use-audio-uploader"
import { usePlayer } from "../hooks/use-player"
import { useStoryEditor } from "../hooks/use-story-editor"
import { useStoryItemEditor } from "../hooks/use-story-item-editor"

const UploadRecordButton: FC = (props) => {
  const { item, revalidate } = useStoryItemEditor()

  const { uploading, uploadRecord } = useAudioUpload(item)

  const inputRef = useRef<HTMLInputElement>()

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files }
    } = event
    if (!files.length || uploading) return
    await uploadRecord(files[0])

    revalidate()
  }
  return (
    <Button
      title={"Upload audio"}
      disabled={uploading}
      onClick={() => {
        inputRef.current.click()
      }}
      {...props}>
      {uploading ? <HourglassEmpty /> : <CloudUpload />}

      <input
        ref={inputRef}
        type="file"
        accept="audio/mp3"
        hidden
        disabled={uploading}
        onChange={handleChange}
      />
    </Button>
  )
}

const TranscribeButton: FC = (props) => {
  const { transport } = useCore()

  const { item, revalidate } = useStoryItemEditor()

  const [transcribing, setTranscribing] = useState(false)

  return (
    <Button
      title={"Transcribe audio"}
      disabled={transcribing}
      onClick={async () => {
        setTranscribing(true)
        await transport.patch("/story/item", {
          id: item._id,
          manuallyEditedTranscript: false
        })
        await transport.patch("audio/transcribe", {
          itemId: item._id,
          itemModel: AudioModel.StoryItem
        })

        await revalidate()
        setTranscribing(false)
      }}
      {...props}>
      <SubtitlesIcon />
    </Button>
  )
}

const StoryItemAudioPlayer: FC = (props) => {
  const { item } = useStoryItemEditor()

  const { state, controls } = usePlayer({
    defaultSrc: {
      itemId: item.id,
      itemModel: AudioModel.StoryItem
    }
  })

  return (
    <Button
      {...props}
      onClick={() => (state?.paused ? controls.play() : controls.pause())}>
      {state?.paused ? <PlayCircleOutline /> : <PauseCircleOutline />}
    </Button>
  )
}

export const StoryItemEditorAudioGroup: FC = () => {
  const { pingData } = useCore()
  const { readOnly } = useStoryEditor()

  const { item, isValidating, isDirty } = useStoryItemEditor()

  return (
    <ButtonGroup
      disabled={isDirty}
      color={item?.hasAudio ? "inherit" : "error"}
      variant="outlined"
      style={{ height: 40 }}>
      {!readOnly && (
        <Button
          title="Open audio recorder"
          onClick={async () => {
            window.open(
              `${pingData?.appOrigin}/coach/record/${item?.id}`,
              "Storied Recording Tool",
              "height=720,width=420"
            )
          }}>
          <RecordIcon />
        </Button>
      )}
      {!readOnly && <UploadRecordButton />}
      {!isValidating && item?.hasAudio && <StoryItemAudioPlayer />}
      {!isValidating && item?.hasAudio && <TranscribeButton />}
    </ButtonGroup>
  )
}
