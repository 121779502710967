import type { SelectProps } from "@mui/material"
import { FormControl, InputLabel, Select } from "@mui/material"
import type { FC } from "react"
import { useEffect, useState } from "react"

import type { PromptFormat } from "@considr-it/ponder-entities"
import { useCore } from "@considr-it/ponder-shared"

export const StoryEditorPromptsSelect: FC<
  SelectProps & {
    defaultOption?: string
  }
> = ({ style, defaultOption, ...props }) => {
  const [promptFormats, setPromptFormats] = useState<Array<PromptFormat>>()
  const { transport } = useCore()

  useEffect(() => {
    const getPromptFormats = async () => {
      //Related to #2824. Should finish this when agreeing on template default prompts (yes/no)

      //const resp = await transport.get("/promptFormats&onlyDefaults=1")
      //setPromptFormats(resp.data)

      setPromptFormats([])
    }

    getPromptFormats()
  }, [transport])

  return (
    <FormControl focused style={style}>
      <InputLabel
        style={{
          position: "absolute",
          top: -6,
          left: 8,
          background: "white",
          zIndex: 10,
          padding: "0 8px"
        }}>
        {props.label}
      </InputLabel>

      <Select
        variant="outlined"
        native
        style={{
          height: 40
        }}
        {...props}>
        <option value="">{defaultOption || "None"}</option>
        {promptFormats?.map((k) => (
          <option key={k.id} value={k.text}>
            {k.text}
          </option>
        ))}
      </Select>
    </FormControl>
  )
}
