import { useGlobal } from "shared/hooks/use-global"

const HomePage = () => {
  const { pingData, account } = useGlobal()
  return (
    <div className="root-content">
      <div
        className={`content`}
        style={{
          alignItems: "flex-start"
        }}>
        <h2>
          Welcome to the Storied Admin Portal, {account.profile.givenName}!
        </h2>
        <p>Version {pingData.version}</p>
        <p>
          DISCLAIMER: This is a pre-alpha release of our coaching content tool.
          Please reach out if you have questions.
        </p>
        <p>Thank you for helping us build our product experience!</p>
      </div>
    </div>
  )
}

export default {
  Page: HomePage,
  name: "Home",
  link: "/"
} as IPage
