import {
  AddBoxOutlined,
  ArrowDropDown,
  ArrowDropUp,
  DeleteOutline,
  Save
} from "@mui/icons-material"
import type { TextFieldProps } from "@mui/material"
import {
  Button,
  ButtonGroup,
  Card,
  FormControlLabel,
  FormGroup,
  styled,
  Switch,
  TextField
} from "@mui/material"
import type { FC } from "react"

import { StoryItemType } from "@considr-it/ponder-enums"

import { useStoryEditor } from "../hooks/use-story-editor"
import {
  StoryItemEditorContext,
  useStoryItemEditor,
  useStoryItemEditorProvider
} from "../hooks/use-story-item-editor"
import { StoryItemEditorAudioGroup } from "./story-item-editor-audio"
import { LayoutOptionEditor, LayoutPicker } from "./story-item-editor-layout"

const MainField = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 240px;
  & > div,
  & > button {
    margin: ${({ theme }) => theme.spacing(1)};
  }
`
const EditorCardBody = styled("div")`
  display: flex;
  flex-wrap: wrap;
`

const EditorCard = styled(Card)`
  zoom: 75%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 24px;
`
const SimplifiedEditorCard = styled(Card)`
  zoom: 75%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  margin-bottom: 24px;
`
const EditorContainer = styled("div")`
  position: relative;
  padding: 12px;
  height: 100%;
`
const EditorOptionSection = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
`

export const StoryEditorBody = () => {
  const { story, createItem, readOnly } = useStoryEditor()

  return (
    <EditorContainer>
      {story.items.map((item, i) => (
        <StoryItemEditorContainer key={item} itemId={item} index={i} />
      ))}

      {!readOnly && (
        <EditorOptionSection>
          <Button
            variant="outlined"
            title="Add question below"
            style={{ marginRight: "1%" }}
            onClick={() =>
              createItem(story.items.length, StoryItemType.Question)
            }
            startIcon={<AddBoxOutlined />}>
            Question
          </Button>
          <Button
            variant="outlined"
            title="Add narrative below"
            style={{ marginLeft: "1%" }}
            onClick={() =>
              createItem(story.items.length, StoryItemType.Narrative)
            }
            startIcon={<AddBoxOutlined />}>
            Narrative
          </Button>
        </EditorOptionSection>
      )}

      <div style={{ height: 450 }} />
    </EditorContainer>
  )
}

type StoryItemEditorProps = { itemId: string; index?: number }

const StoryItemEditorContainer: FC<StoryItemEditorProps> = ({
  itemId,
  index
}) => {
  const storyItemEditorHook = useStoryItemEditorProvider(itemId)

  const { storyItemForm, showExpandedView } = storyItemEditorHook

  return (
    <StoryItemEditorContext.Provider value={storyItemEditorHook}>
      <form onSubmit={storyItemForm.handleSubmit}>
        {showExpandedView ? (
          <StoryItemEditor>
            <EditorOptionsMenu itemId={itemId} index={index} />
          </StoryItemEditor>
        ) : (
          <StoryItemEditorSimple>
            <EditorOptionsMenu itemId={itemId} index={index} />
          </StoryItemEditorSimple>
        )}
      </form>
    </StoryItemEditorContext.Provider>
  )
}

const StoryItemEditor: FC = ({ children }) => {
  const { readOnly } = useStoryEditor()

  const { layout, setLayout, storyItemForm } = useStoryItemEditor()

  const commonForm: TextFieldProps = readOnly
    ? {}
    : {
        onChange: storyItemForm.handleChange
      }

  return (
    <EditorCard>
      <EditorCardBody>
        <MainField
          style={{
            flexGrow: 8
          }}>
          <LayoutPicker layout={layout} onChange={setLayout} />
          <TextField
            variant="outlined"
            label="On-screen prompt"
            required
            multiline
            rows={4}
            name="prompt"
            value={storyItemForm.values.prompt}
            error={
              storyItemForm.touched.prompt &&
              Boolean(storyItemForm.errors.prompt)
            }
            helperText={
              storyItemForm.touched.prompt && storyItemForm.errors.prompt
            }
            {...commonForm}
          />
        </MainField>
        <MainField
          style={{
            flexGrow: 6
          }}>
          <TextField
            size="small"
            variant="outlined"
            label="Question name"
            name="header"
            value={storyItemForm.values.header}
            error={
              storyItemForm.touched.header &&
              Boolean(storyItemForm.errors.header)
            }
            helperText={
              storyItemForm.touched.header && storyItemForm.errors.header
            }
            {...commonForm}
          />

          <TextField
            variant="outlined"
            label="Audio script"
            name="transcript"
            multiline
            rows={4}
            value={storyItemForm.values.transcript}
            error={
              storyItemForm.touched.transcript &&
              Boolean(storyItemForm.errors.transcript)
            }
            helperText={
              storyItemForm.touched.transcript &&
              storyItemForm.errors.transcript
            }
            {...commonForm}
          />
        </MainField>
        <MainField
          style={{
            flexGrow: 4
          }}>
          <StoryItemEditorAudioGroup />
          <LayoutOptionEditor layout={layout} />
          <TextField
            variant="outlined"
            label="Ai Summary Prompt"
            name="aiSummaryPrompt"
            multiline
            rows={4}
            value={storyItemForm.values.aiSummaryPrompt}
            error={
              storyItemForm.touched.aiSummaryPrompt &&
              Boolean(storyItemForm.errors.aiSummaryPrompt)
            }
            helperText={
              storyItemForm.touched.aiSummaryPrompt &&
              storyItemForm.errors.aiSummaryPrompt
            }
            {...commonForm}
          />
        </MainField>
      </EditorCardBody>
      {!readOnly && children}
    </EditorCard>
  )
}

const StoryItemEditorSimple: FC = ({ children }) => {
  const { readOnly } = useStoryEditor()

  const { layout, setLayout, storyItemForm } = useStoryItemEditor()

  const commonForm: TextFieldProps = readOnly
    ? {}
    : {
        onChange: storyItemForm.handleChange
      }

  return (
    <SimplifiedEditorCard>
      <EditorCardBody>
        <MainField
          style={{
            flexGrow: 1,
            flexBasis: 200
          }}>
          <LayoutPicker layout={layout} onChange={setLayout} />
        </MainField>
        <MainField
          style={{
            flexGrow: 15
          }}>
          <TextField
            variant="outlined"
            label="On-screen prompt"
            required
            multiline
            rows={1}
            name="prompt"
            value={storyItemForm.values.prompt}
            error={
              storyItemForm.touched.prompt &&
              Boolean(storyItemForm.errors.prompt)
            }
            helperText={
              storyItemForm.touched.prompt && storyItemForm.errors.prompt
            }
            {...commonForm}
          />
        </MainField>
        <MainField
          style={{
            flexGrow: 1
          }}>
          <StoryItemEditorAudioGroup />
        </MainField>
        <MainField
          style={{
            flexGrow: 1
          }}>
          {!readOnly && children}
        </MainField>
      </EditorCardBody>
    </SimplifiedEditorCard>
  )
}

const EditorOptionsMenu: FC<StoryItemEditorProps> = ({ itemId, index }) => {
  const { story, createItem, deleteItem, reorderItem } = useStoryEditor()
  const { showExpandedView, toggleExpandedView, isDirty } = useStoryItemEditor()

  return (
    <EditorOptionSection>
      <ButtonGroup size="small">
        <Button
          title="Move question up"
          disabled={index === 0}
          onClick={() => reorderItem(index, index - 1)}>
          <ArrowDropUp />
        </Button>
        <Button
          title="Move question down"
          disabled={index === story.items.length - 1}
          onClick={() => reorderItem(index, index + 1)}>
          <ArrowDropDown />
        </Button>

        <Button title="Remove question" onClick={() => deleteItem(itemId)}>
          <DeleteOutline />
        </Button>

        <Button
          title="Add question below"
          onClick={() => createItem(index + 1, StoryItemType.Question)}>
          <AddBoxOutlined />
        </Button>

        {isDirty && (
          <Button
            color="primary"
            variant="contained"
            startIcon={<Save />}
            type="submit">
            Save
          </Button>
        )}
      </ButtonGroup>
      <FormGroup style={{ marginLeft: "20px" }}>
        <FormControlLabel
          control={
            <Switch
              title="Advanced Options"
              checked={showExpandedView}
              onClick={toggleExpandedView}></Switch>
          }
          style={{ fontWeight: "bold" }}
          label="Advanced"
        />
      </FormGroup>
    </EditorOptionSection>
  )
}
