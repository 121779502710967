import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import { useGlobal } from "shared/hooks/use-global"

import {
  CopyAndOpenButtonGroup,
  DeleteButton,
  FeaturesToggleGroup,
  PublishButtonGroup,
  RefreshAndCloneButtonGroup
} from "features/editor/components/edit-panel-group"
import { MaterialAccessEditGroup } from "features/editor/components/material-access-editor"
import { StoryEditor } from "features/editor/components/story-editor"
import {
  EditorStoryViewerContainer,
  EditPanel
} from "features/editor/components/story-viewer"
import {
  StoryEditorContext,
  useStoryEditorProvider
} from "features/editor/hooks/use-story-editor"
import { useStoryViewer } from "features/editor/hooks/use-story-viewer"
import { cloneMaterial } from "features/editor/redux/editor-slice"
import {
  removeMaterial,
  setActiveMaterial,
  updateMaterial
} from "features/explore/redux/explore-slice"

import Editor from "pages/editor"

const AdminSettingGroup = () => {
  const dispatch = useDispatch()
  const { isAdmin } = useGlobal()

  const { material, isSaving } = useStoryViewer({
    onSelector: (state) => state.explore
  })

  if (!isAdmin) return null

  return (
    <>
      <DeleteButton
        disabled={isSaving}
        onPress={() => {
          dispatch(setActiveMaterial(null))
          dispatch(removeMaterial(material.id))
        }}
      />

      <FeaturesToggleGroup />

      <MaterialAccessEditGroup />

      {/* <AudioButtonGroup material={material} /> */}
    </>
  )
}

export const ExploreStoryViewer = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { isAdmin } = useGlobal()
  const storyEditorHook = useStoryEditorProvider({
    readOnly: !isAdmin,
    updateMaterial,
    onSelector: (state) => state.explore
  })
  const { material, story, isLoading } = storyEditorHook

  if (!story || isLoading) return null

  return (
    <StoryEditorContext.Provider value={storyEditorHook}>
      <EditorStoryViewerContainer>
        <EditPanel>
          <AdminSettingGroup />
          <CopyAndOpenButtonGroup />

          <RefreshAndCloneButtonGroup
            onClone={() => {
              dispatch(cloneMaterial(material.id))
              history.push(Editor.link)
            }}
          />

          {isAdmin && <PublishButtonGroup />}
        </EditPanel>

        <StoryEditor />
      </EditorStoryViewerContainer>
    </StoryEditorContext.Provider>
  )
}
