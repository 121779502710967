import { List, styled } from "@mui/material"
import type { RootState } from "core/reducer"
import type { FC } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Virtuoso } from "react-virtuoso"

import type { StoryItem } from "@considr-it/ponder-entities"
import { H2 } from "@considr-it/ponder-shared/src/components/typography"

import { StoryListItem } from "./components/story-list-item"
import {
  fetchShareEdges,
  fetchStory,
  setActiveStoryItem,
  setCheckedTranscriptsForQuery,
  setCheckedUsersForQuery
} from "./redux/query-slice"

const ClientListContainer = styled(List)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const ActiveMaterialTitle = ({ name }) => {
  return (
    <div style={{ width: "50%", padding: "24px" }}>
      <H2>{name}</H2>
    </div>
  )
}

export const StoryViewer: FC = () => {
  const dispatch = useDispatch()

  const activeMaterial = useSelector(
    (state: RootState) => state.query.activeMaterial
  )

  const activeStory = useSelector((state: RootState) => state.query.activeStory)
  const activeStoryItem = useSelector(
    (state: RootState) => state.query.activeStoryItem
  )

  useEffect(() => {
    dispatch(setActiveStoryItem(null))
    dispatch(fetchStory())
    dispatch(fetchShareEdges())
  }, [activeMaterial, dispatch])

  useEffect(() => {
    dispatch(setCheckedTranscriptsForQuery([]))
    dispatch(setCheckedUsersForQuery([]))
  }, [activeStoryItem, dispatch])

  if (activeMaterial === null || activeStory === null) return null

  return (
    <ClientListContainer
      subheader={<ActiveMaterialTitle name={activeStory.name} />}>
      <Virtuoso
        data={activeStory["items"] as StoryItem[]}
        components={{
          Footer: () => (
            <div
              style={{
                padding: "1rem",
                textAlign: "center",
                height: "50vh"
              }}>
              - End reached -
            </div>
          )
        }}
        itemContent={(index, data) => (
          <StoryListItem key={`${data["id"]}${index}`} storyItem={data} />
        )}
      />
    </ClientListContainer>
  )
}
