import NoteAddIcon from "@mui/icons-material/NoteAdd"
import {
  AppBar,
  Button,
  ButtonGroup,
  Card,
  Divider,
  styled
} from "@mui/material"
import { useDispatch } from "react-redux"

import { useWide, useWideVisible } from "shared/hooks/use-screen-size"

import { QuickAndDirtyAccordionButton } from "features/editor/components/accordion"
import { EditorMaterialList } from "features/editor/components/material-list"
import { EditorStoryViewer } from "features/editor/components/story-viewer"
import { createMaterial } from "features/editor/redux/editor-slice"

const EditorContainer = styled(Card)<{ desktop?: boolean }>`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  width: 100%;
  height: 100%;
`

export const EditorList = styled("div")`
  flex: 0 0 280px;
  height: 100%;
  overflow: auto;
`

export const ResponsiveEditorContainer = (props) => {
  const isWideScreen = useWide()

  return (
    <EditorContainer
      style={{
        flexDirection: isWideScreen ? "row" : "column"
      }}
      {...props}
    />
  )
}

const EditorHeader = styled(AppBar)`
  &.MuiAppBar-positionSticky {
    padding: 8px;
  }
`

const EditorPage = () => {
  const dispatch = useDispatch()

  const [hideSideBar, setHideSideBar] = useWideVisible()

  return (
    <div className="root-content">
      <div className="content">
        <ResponsiveEditorContainer>
          <EditorList
            style={
              hideSideBar
                ? {
                    flex: 0,
                    width: 0
                  }
                : undefined
            }>
            <EditorHeader color="transparent" position="sticky">
              <ButtonGroup fullWidth>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<NoteAddIcon />}
                  onClick={() => dispatch(createMaterial())}>
                  New inquiry
                </Button>
              </ButtonGroup>
            </EditorHeader>

            <EditorMaterialList />
          </EditorList>
          <QuickAndDirtyAccordionButton
            isHidden={hideSideBar}
            onToggle={() => setHideSideBar((h) => !h)}
          />
          <Divider orientation="vertical" flexItem />
          <EditorStoryViewer />
        </ResponsiveEditorContainer>
      </div>
    </div>
  )
}

export default {
  Page: EditorPage,
  link: "/editor",
  name: "Editor"
} as IPage
