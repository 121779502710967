import { Auth0Provider, useAuth0 } from "@auth0/auth0-react"
import { configureStore } from "@reduxjs/toolkit"
import { rootReducer } from "core/reducer"
import type { FC } from "react"
import { createContext, useContext, useMemo, useState } from "react"
import { Provider } from "react-redux"

import type { PingResponse, User } from "@considr-it/ponder-entities"
import { UserRole } from "@considr-it/ponder-enums"
import { CoreContext, Transport } from "@considr-it/ponder-shared"

import { usePlayer } from "features/editor/hooks/use-player"

import Login from "pages/login"

export const publicTransport = Transport.createPublicTransport(
  process.env.REACT_APP_SERVER_API
)

type GlobalProviderProps = {
  pingData: PingResponse
}

export const useGlobalProvider = ({ pingData }: GlobalProviderProps) => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0()
  const transport = Transport.useTransport(process.env.REACT_APP_SERVER_API, {
    getAccessToken: getAccessTokenSilently,
    isAuthenticated
  })
  const {
    data: account,
    revalidate: revalidateAccount,
    isValidating: isAccountValidating
  } = Transport.useTransportSWR<User>(
    transport,
    isAuthenticated ? "auth" : null,
    {
      onError(err) {
        if (err.message.includes("424")) {
          logout()
        }
      }
    }
  )

  const isAdmin = useMemo(() => account?.role === UserRole.Admin, [account])

  const updateAccount = async (data: Partial<User>) => {
    try {
      await transport.patch("auth", data)
      revalidateAccount()
    } catch (error) {
      alert("Cannot update account")
    }
  }

  const changeAccountRole = async (userEmail: string) => {
    try {
      await transport.patch("/auth", { userEmail: userEmail })
      alert(`${userEmail} successfully changed to coach`)
    } catch (error) {
      alert("Cannot update account")
    }
  }

  return {
    updateAccount,
    isAdmin,
    changeAccountRole,
    transport,
    pingData,

    account,
    revalidateAccount,
    isAccountValidating,

    getAccessToken: getAccessTokenSilently,

    getQuery: () => ({}),

    router: null,

    usePlayer,

    trackError: console.error,
    trackEvent: console.log
  }
}

export type GlobalContextProps = ReturnType<typeof useGlobalProvider>

export const GlobalContext = createContext<GlobalContextProps>(null)

export const useGlobal = () => useContext(GlobalContext)

export const GlobalAuthProvider: FC<GlobalProviderProps> = ({
  children,
  pingData
}) => {
  if (!pingData) return null

  return (
    <Auth0Provider
      cacheLocation={"localstorage"}
      useRefreshTokens
      redirectUri={window.location.origin}
      clientId={pingData?.oAuthId}
      domain={pingData?.oAuthDomain}
      audience={pingData?.oAuthAudience}>
      {children}
    </Auth0Provider>
  )
}

const ReduxProvider: FC = ({ children }) => {
  const global = useGlobal()

  const [store] = useState(() =>
    configureStore({
      reducer: rootReducer,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          thunk: {
            extraArgument: global
          }
        })
    })
  )

  return <Provider store={store}>{children}</Provider>
}

export const GlobalProvider: FC<GlobalProviderProps> = ({
  children,
  pingData
}) => {
  const globalProvider = useGlobalProvider({ pingData })

  return (
    <GlobalContext.Provider value={globalProvider}>
      <CoreContext.Provider value={globalProvider}>
        {globalProvider.account ? (
          <ReduxProvider>{children}</ReduxProvider>
        ) : (
          <Login.Page />
        )}
      </CoreContext.Provider>
    </GlobalContext.Provider>
  )
}
