import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import type { AppThunk } from "core/reducer"

import type { Material, Story, User } from "@considr-it/ponder-entities"
import { MaterialAccess } from "@considr-it/ponder-enums"

import type { EditorState } from "features/editor/redux/editor-slice"
import { initialEditorReducers } from "features/editor/redux/editor-slice"
import { generateMaterialThunk } from "features/editor/redux/material-thunk"

interface ExploreState extends EditorState {
  activeAuthor: User
  authors: Array<User>
}

const initialState: ExploreState = {
  activeAuthor: null,
  activeMaterial: null,
  materials: null,
  authors: null,
  isLoading: false,
  isSaving: false,
  error: null
}

const explore = createSlice({
  name: "explore",
  initialState,
  reducers: {
    ...initialEditorReducers,
    setActiveAuthor: (
      state: ExploreState,
      { payload }: PayloadAction<User>
    ) => {
      state.activeAuthor = payload
    },
    setAuthors: (
      state: ExploreState,
      { payload }: PayloadAction<Array<User>>
    ) => {
      state.authors = payload
    }
  }
})

export const {
  setActiveAuthor,
  setActiveMaterial,
  setAuthors,
  setMaterials,
  setIsSaving,
  setIsLoading
} = explore.actions

export const exploreReducer = explore.reducer

export const fetchAuthors =
  (): AppThunk =>
  async (dispatch, getState, { transport }) => {
    const {
      explore: { activeAuthor }
    } = getState()

    try {
      const { data } = await transport.get("coach/author")

      dispatch(setAuthors(data))

      if (!activeAuthor && data.length > 0) {
        dispatch(setActiveAuthor(data[0]))
      }
    } catch (error) {
      alert(error)
    }
  }

export const { fetchMaterials, removeMaterial, updateMaterial } =
  generateMaterialThunk({
    ...explore.actions,
    async getMaterials(state, transport) {
      const {
        explore: { activeAuthor }
      } = state

      if (!activeAuthor) return null

      try {
        const r = await transport.get<Array<Material>>("material/explore", {
          params: {
            coachId: activeAuthor.id
          }
        })

        return r.data
          .sort((a, b) => {
            const aPub = a.published as Story
            const bPub = b.published as Story
            if (bPub?.name && aPub?.name)
              return bPub.name
                .toLowerCase()
                .localeCompare(aPub.name.toLowerCase())
            else return bPub?.name ? 1 : 0
          })
          .sort((a, b) =>
            b.access === MaterialAccess.Template &&
            a.access !== MaterialAccess.Template
              ? 1
              : -1
          )
      } catch (e) {
        return null
      }
    },
    onSelector: (state) => state.explore
  })

export const updateCoachRanking =
  (rankMap): AppThunk =>
  async (dispatch, _, { transport }) => {
    try {
      await transport.post("coach/ranking", {
        rankMap
      })
      dispatch(fetchAuthors())
    } catch (error) {
      alert(error)
    }
  }
