import { Divider, styled } from "@mui/material"

import { ExploreAuthorList } from "features/explore/components/author-list"
import { ExploreMaterialList } from "features/explore/components/material-list"
import { ExploreStoryViewer } from "features/explore/components/story-viewer"

import { EditorList, ResponsiveEditorContainer } from "./editor"

const AuthorList = styled("div")`
  flex: 0 0 240px;
  height: 100%;
  overflow: auto;
`

const ExplorePage = () => {
  return (
    <div className="root-content">
      <div className="content">
        <ResponsiveEditorContainer>
          <AuthorList>
            <ExploreAuthorList />
          </AuthorList>
          <Divider orientation="vertical" flexItem />

          <EditorList>
            <ExploreMaterialList />
          </EditorList>
          <Divider orientation="vertical" flexItem />
          <ExploreStoryViewer />
        </ResponsiveEditorContainer>
      </div>
    </div>
  )
}

export default {
  Page: ExplorePage,
  link: "/templates",
  name: "Templates"
} as IPage
