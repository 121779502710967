import {
  AddCircleOutline,
  ExitToApp,
  HearingOutlined,
  MenuOpenOutlined,
  SettingsVoiceOutlined
} from "@mui/icons-material"
import type { SelectChangeEvent } from "@mui/material"
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material"
import type { FC } from "react"
import { useState } from "react"
import { v1 as uuid } from "uuid"

import { StoryButtonTone, StoryItemLayout } from "@considr-it/ponder-enums"
import { Button as SharedButton, Color } from "@considr-it/ponder-shared"

import { useStoryEditor } from "../hooks/use-story-editor"
import { useStoryItemEditor } from "../hooks/use-story-item-editor"
import { StoryEditorGotoSelect } from "./story-editor-goto-select"

const storyItemLayoutMap = {
  [StoryItemLayout.Default]: "Free response",
  [StoryItemLayout.AudioOnly]: "Listen only",
  [StoryItemLayout.TextOnly]: "Select from choices"
}

const storyItemLayoutTooltipsMap = {
  [StoryItemLayout.Default]:
    "Clients can respond with voice or text to this prompt",
  [StoryItemLayout.AudioOnly]: "Clients can only listen or read this prompt",
  [StoryItemLayout.TextOnly]: "Clients are given options to pick from"
}

const storyItemLayoutIconMap = {
  [StoryItemLayout.Default]: <SettingsVoiceOutlined />,
  [StoryItemLayout.AudioOnly]: <HearingOutlined />,
  [StoryItemLayout.TextOnly]: <MenuOpenOutlined />
}

const storyItemLayoutColorMap = {
  [StoryItemLayout.Default]: Color.bg.orange,
  [StoryItemLayout.AudioOnly]: Color.bg.blue,
  [StoryItemLayout.TextOnly]: Color.text.gray
}

const storyItemLayouts = Object.keys(storyItemLayoutMap)

type LayoutPickerProps<T = StoryItemLayout> = {
  layout: StoryItemLayout
  onChange?: (v: T) => void
}

export const LayoutPicker: FC<LayoutPickerProps> = ({ layout, onChange }) => {
  const { readOnly } = useStoryEditor()

  return (
    <FormControl>
      <InputLabel
        style={{
          top: -6,
          left: 8,
          background: "white",
          zIndex: 10,
          padding: "0 8px"
        }}>
        Layout
      </InputLabel>
      <Select
        disabled={readOnly}
        SelectDisplayProps={{
          style: {
            display: "flex",
            alignItems: "center"
          }
        }}
        style={{
          height: 40,
          borderWidth: 4,
          borderStyle: "solid",
          borderColor: storyItemLayoutColorMap[layout]
        }}
        value={layout}
        onChange={(e) => onChange(e.target.value as StoryItemLayout)}
        variant="outlined">
        {storyItemLayouts.map((l, i) => (
          <MenuItem key={l + i} title={storyItemLayoutTooltipsMap[l]} value={l}>
            {storyItemLayoutIconMap[l]} {storyItemLayoutMap[l]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const storyItemButtonToneMap = SharedButton.toneMap

const storyItemButtonTones = Object.keys(storyItemButtonToneMap)

export const LayoutOptionEditor: FC<LayoutPickerProps> = ({ layout }) => {
  const { item, storyItemForm, buttonMap, setButtonMap } = useStoryItemEditor()
  const { readOnly } = useStoryEditor()

  if (!item) return null

  if (layout !== StoryItemLayout.TextOnly)
    return (
      <StoryEditorGotoSelect
        defaultOption="Next"
        label="Goto"
        value={storyItemForm.values.goto}
        onChange={storyItemForm.handleChange}
        disabled={readOnly}
        name="goto"
      />
    )

  return (
    <>
      {Object.keys(buttonMap).map((bk) => (
        <StoryItemButtonEditor key={bk} buttonKey={bk} />
      ))}
      {!readOnly && (
        <Button
          startIcon={<AddCircleOutline />}
          onClick={() =>
            setButtonMap((btns) => ({
              ...btns,
              [uuid()]: {
                text: "Proceed",
                tone: StoryButtonTone.Primary
              }
            }))
          }>
          Add option
        </Button>
      )}
      <Divider />
      <ExitButtonEditor />
    </>
  )
}

const ExitButtonEditor = () => {
  const { readOnly } = useStoryEditor()
  const { exit, setExit, setOtherDirty } = useStoryItemEditor()

  if (readOnly && !exit) return null

  return exit ? (
    <StoryButtonEditor
      onRemove={() => {
        setExit(null)
        setOtherDirty(true)
      }}
      defaulText={exit.text}
      onChangeText={(e) => {
        setExit({ text: e.target.value })
        setOtherDirty(true)
      }}
    />
  ) : (
    <Button
      startIcon={<ExitToApp />}
      onClick={() => {
        setExit({
          text: "Exit"
        })
        setOtherDirty(true)
      }}>
      Add exit button
    </Button>
  )
}

const StoryButtonEditor = ({
  readOnly = false,
  defaulText = "",
  defaultGoto = "",
  defaulTone = StoryButtonTone.Primary,
  onChangeText = null as (
    _: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void,
  onChangeGoto = null as (_: SelectChangeEvent<string>) => void,
  onChangeTone = null as (_: SelectChangeEvent<string>) => void,
  onRemove = () => null
}) => {
  return (
    <div
      style={{
        display: "flex"
      }}>
      {!readOnly && (
        <button onClick={onRemove} style={{ border: "none" }}>
          -
        </button>
      )}
      <TextField
        disabled={readOnly}
        style={{
          marginRight: 8,
          flexGrow: 1,
          flex: 2
        }}
        size="small"
        variant="outlined"
        label="Text"
        required
        defaultValue={defaulText}
        onChange={onChangeText}
      />
      {onChangeGoto && (
        <StoryEditorGotoSelect
          style={{
            marginRight: 8,
            flexGrow: 1,
            flex: 2
          }}
          defaultOption="Next"
          label="Goto"
          defaultValue={defaultGoto}
          onChange={onChangeGoto}
          disabled={readOnly}
          name="goto"
        />
      )}

      {onChangeTone && (
        <Select
          disabled={readOnly}
          title="Button color"
          SelectDisplayProps={{
            style: {
              display: "flex",
              alignItems: "center"
            }
          }}
          style={{
            height: 40
          }}
          value={defaulTone}
          onChange={onChangeTone}
          variant="outlined">
          {storyItemButtonTones.map((l, i) => (
            <MenuItem key={l + i} value={l}>
              <ButtonColor {...storyItemButtonToneMap[l]} />
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  )
}

const StoryItemButtonEditor = ({ buttonKey }) => {
  const { buttonMap, setButtonMap, setOtherDirty } = useStoryItemEditor()

  const { readOnly } = useStoryEditor()

  const [buttonTone, setButtonTone] = useState(buttonMap[buttonKey].tone)

  return (
    <StoryButtonEditor
      readOnly={readOnly}
      onRemove={() =>
        setButtonMap((btnm) => {
          delete btnm[buttonKey]
          return { ...btnm }
        })
      }
      defaulText={buttonMap[buttonKey].text}
      onChangeText={(e) => {
        buttonMap[buttonKey].text = e.target.value
        setOtherDirty(true)
      }}
      defaultGoto={buttonMap[buttonKey].goto}
      onChangeGoto={(e) => {
        buttonMap[buttonKey].goto = e.target.value as string
        setOtherDirty(true)
      }}
      defaulTone={buttonTone}
      onChangeTone={(e) => {
        setButtonTone(
          (buttonMap[buttonKey].tone = e.target.value as StoryButtonTone)
        )
        setOtherDirty(true)
      }}
    />
  )
}

const ButtonColor = ({ background, color, border }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 16,
      height: 16,
      background,
      border: `2px solid ${border}`,
      borderRadius: "50%"
    }}>
    <div
      style={{
        borderRadius: "50%",
        width: 4,
        height: 4,
        background: color
      }}></div>
  </div>
)
