import { PersonRounded } from "@mui/icons-material"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete"
import Avatar from "@mui/material/Avatar"
import Checkbox from "@mui/material/Checkbox"
import Chip from "@mui/material/Chip"
import TextField from "@mui/material/TextField"
import type { RootState } from "core/reducer"
import { useDispatch, useSelector } from "react-redux"

import type { CoachClientEdge, User } from "@considr-it/ponder-entities"
import { SystemDialog, useCore } from "@considr-it/ponder-shared"

import { fetchClientEdges } from "features/clientele/redux/clientele-slice"

const filter = createFilterOptions()

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />

// use this to type cast the extra maybe
type AutoExtraData = {
  extra: {
    inputValue: string
    title: string
  }
}

/**
 *
 */
export const ClientAutocompleteInput = ({
  checkedClientMap = {} as Record<string, boolean>,
  onChange = (_v: Record<string, boolean>) => null
}) => {
  const dispatch = useDispatch()
  const { transport } = useCore()

  const coachClientEdges = useSelector(
    (state: RootState) => state.clientele.coachClientEdges
  )

  return (
    <Autocomplete
      multiple
      options={coachClientEdges}
      disableCloseOnSelect
      value={coachClientEdges.filter((edge) => checkedClientMap[edge.id])}
      filterOptions={(options, params) => {
        const filtered = filter(options, params) as any

        if (params.inputValue !== "") {
          filtered.push({
            extra: {
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`
            }
          })
        }

        return filtered
      }}
      getOptionLabel={(ce: CoachClientEdge & AutoExtraData) => {
        if (ce.extra) {
          return ce.extra.title
        }
        const client = ce.client as User
        return `${client?.profile.name || ""} <${ce.clientEmail}>`
      }}
      style={{
        paddingTop: 16,
        maxWidth: 400
      }}
      renderTags={(value, getTagProps) =>
        value.map((ce, index) => (
          <Chip
            {...getTagProps({ index })}
            label={(ce.client as User)?.profile.name || ce.clientEmail}
            avatar={
              ce.client ? (
                <Avatar src={(ce.client as User).profile.imageUrl} />
              ) : (
                <Avatar>
                  <PersonRounded />
                </Avatar>
              )
            }
          />
        ))
      }
      renderOption={(
        props,
        ce: CoachClientEdge & AutoExtraData,
        { selected }
      ) => {
        if (ce.extra) {
          return <li {...props}>{ce.extra.title}</li>
        }

        const client = ce.client as User
        return (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${client?.profile.name || ""} <${ce.clientEmail}>`}
          </li>
        )
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Find or add clients"
          placeholder="Name or email"
        />
      )}
      onChange={async (_e, value: Array<CoachClientEdge & AutoExtraData>) => {
        // create the coach-client-edge
        // get the id
        // put that in onChange
        const lastItem = value[value.length - 1]

        if (lastItem?.extra) {
          const { inputValue } = lastItem.extra

          // check inputValue is not a valid email
          if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i.test(inputValue)) {
            SystemDialog.alert("Invalid email", "Please provide a valid email.")
            return
          }

          const { data: ceId } = await transport.post<string>("/coach/client", {
            clientEmail: inputValue
          })

          dispatch(fetchClientEdges())

          value[value.length - 1].id = ceId
        }

        onChange(
          value.reduce((acc, ce: CoachClientEdge) => {
            acc[ce.id] = true
            return acc
          }, {})
        )
      }}
    />
  )
}
