import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material"

import { useWide } from "shared/hooks/use-screen-size"

/**
 * Usage:
 * <QuickAndDirtyAccordionButton isActive={isWideScreen}
 *      onToggle={()=> setIsActive(h => !h)}>
 */
export const QuickAndDirtyAccordionButton = ({
  isHidden = false,
  irresponsive = false,
  onToggle = () => null
}) => {
  const isWideScreen = useWide()

  return (
    <button
      style={{
        border: "none",
        padding: 0
      }}
      onClick={onToggle}>
      {isWideScreen && !irresponsive ? (
        isHidden ? (
          <ChevronRight />
        ) : (
          <ChevronLeft />
        )
      ) : isHidden ? (
        <KeyboardArrowDown />
      ) : (
        <KeyboardArrowUp />
      )}
    </button>
  )
}
