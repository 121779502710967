import { Divider, styled } from "@mui/material"
import type { FC } from "react"

import { StoryEditorBody } from "./story-editor-body"
import { StoryEditorHeader } from "./story-editor-header"

const StoryEditorContainer = styled("div")`
  height: 100%;
  width: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background: whitesmoke;
`

export const StoryEditor: FC = () => {
  return (
    <StoryEditorContainer>
      <StoryEditorHeader />
      <Divider />
      <StoryEditorBody />
    </StoryEditorContainer>
  )
}
