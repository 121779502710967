import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
import type { AppThunk } from "core/reducer"

import type { CoachClientEdge, User } from "@considr-it/ponder-entities"
import { quickTimer } from "@considr-it/ponder-utils"

import type { RemoteState } from "shared/redux/loading"

interface ClienteleState extends RemoteState {
  activeAuthor: User
  coachClientEdges: Array<CoachClientEdge>
}

const initialState: ClienteleState = {
  activeAuthor: null,
  coachClientEdges: null,
  isLoading: false,
  error: null
}

const clientele = createSlice({
  name: "clientele",
  initialState,
  reducers: {
    setActiveAuthor: (
      state: ClienteleState,
      { payload }: PayloadAction<User>
    ) => {
      state.activeAuthor = payload
    },
    setClientEdges: (
      state: ClienteleState,
      { payload }: PayloadAction<Array<CoachClientEdge>>
    ) => {
      state.coachClientEdges = payload
    }
  }
})

export const { setActiveAuthor, setClientEdges: setClients } = clientele.actions

export const clienteleReducer = clientele.reducer

export const removeClient =
  (data: Partial<CoachClientEdge>): AppThunk =>
  async (dispatch, _, { transport }) => {
    try {
      await transport.delete("/coach/client", {
        params: {
          id: data.id
        }
      })

      dispatch(fetchClientEdges())
    } catch (error) {
      alert(error.message)
    }
  }

export const verifyClient =
  (
    data: Partial<CoachClientEdge> & {
      protocol: "sms" | "mail"
    }
  ): AppThunk =>
  async (dispatch, _, { transport }) => {
    try {
      await transport.get("coach/client/verify", {
        params: {
          id: data.id,
          protocol: data.protocol
        }
      })

      await quickTimer(1000)

      dispatch(fetchClientEdges())
    } catch (error) {
      alert(error.message)
    }
  }

export const fetchClientEdges =
  (): AppThunk =>
  async (dispatch, getState, { transport }) => {
    const { activeAuthor } = getState().clientele

    try {
      const resp = await transport.get("coach/client", {
        params: {
          coachId: activeAuthor?.id
        }
      })

      dispatch(setClients(resp.data))
    } catch (error) {
      alert(error.message)
    }
  }
