import { Button, TextField } from "@mui/material"
import type { RootState } from "core/reducer"
import type { FC } from "react"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CircularLoader } from "@considr-it/ponder-shared"

import { sendQuery } from "./redux/query-slice"

export const StoryPlayground: FC = () => {
  const dispatch = useDispatch()

  const activeMaterial = useSelector(
    (state: RootState) => state.query.activeMaterial
  )

  const isLoading = useSelector((state: RootState) => state.query.isLoading)

  const queryResult = useSelector((state: RootState) => state.query.queryResult)
  const [query, setQuery] = useState("")

  const handleQueryChange = (event) => {
    setQuery(event.target.value)
  }

  const handleSendQuery = () => {
    if (!!query) {
      dispatch(sendQuery(query))
    } else {
      alert("Please enter a query")
    }
  }
  const activeStory = useSelector((state: RootState) => state.query.activeStory)

  if (activeMaterial === null || activeStory === null) return null

  return (
    <div
      style={{
        display: "flex",
        width: "50%",
        height: "100%",
        justifyContent: "flex-start",
        padding: "8px",
        flexDirection: "column"
      }}>
      <div
        style={{
          width: "100%",
          height: "10%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row"
        }}>
        <TextField
          value={query}
          style={{ width: "70%" }}
          id="outlined-basic"
          label="Query"
          variant="outlined"
          onChange={handleQueryChange}
        />
        <Button
          style={{ width: "30%", height: "80%" }}
          onClick={handleSendQuery}>
          Send
        </Button>
      </div>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <div
          style={{
            border: "1px solid black",
            width: "100%",
            height: "85%",
            padding: "8px"
          }}>
          <p>{!!queryResult ? queryResult : ""}</p>
        </div>
      )}
    </div>
  )
}
