import type { TextFieldProps } from "@mui/material"
import { TextField } from "@mui/material"
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import type { Dispatch, FC } from "react"
import { useEffect, useState } from "react"

export const PhoneInput: FC<
  Partial<TextFieldProps> & {
    value: string
    onUpdate: Dispatch<string>
  }
> = ({ value = "", onUpdate, onChange: _onChange, ...props }) => {
  const [rawNumber, setRawNumber] = useState(value)

  const [error, setError] = useState(false)

  useEffect(() => {
    if (!isValidPhoneNumber(rawNumber, "US")) {
      return
    }
    if (value === "") setRawNumber("")
  }, [value, rawNumber])

  return (
    <TextField
      value={rawNumber}
      onChange={(e) => {
        const newValue = e.target.value
        setRawNumber(newValue)

        if (isValidPhoneNumber(newValue, "US")) {
          const newNumber = parsePhoneNumber(
            e.target.value,
            "US"
          ).formatInternational()

          onUpdate(newNumber)

          setError(false)
        } else {
          if (!newValue) {
            setError(false)
          } else {
            setError(true)
          }
        }
      }}
      error={error}
      helperText={error && "Invalid phone number"}
      aria-label="SMS contact number"
      placeholder="To receive sms notification"
      label="SMS number"
      variant="outlined"
      {...props}
    />
  )
}
