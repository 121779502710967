import { PauseCircleOutline, PlayCircleOutline } from "@mui/icons-material"
import {
  Button,
  ButtonGroup,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material"
import type { RootState } from "core/reducer"
import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import type { StoryLogShareEdge, User } from "@considr-it/ponder-entities"
import { AudioModel } from "@considr-it/ponder-enums"
import { P } from "@considr-it/ponder-shared/src/components/typography"

import { usePlayer } from "features/editor/hooks/use-player"

import {
  addTranscriptToQuery,
  fetchStoryLogItem,
  setActiveShareEdgeLogToView,
  setCheckedUsersForQuery
} from "../redux/query-slice"

export function StoryItemData() {
  const dispatch = useDispatch()

  const activeShareEdges = useSelector(
    (state: RootState) => state.query.activeShareEdges
  )

  const activeStoryLogItem = useSelector(
    (state: RootState) => state.query.activeStoryLogItem
  )

  const checkedUsersForQuery = useSelector(
    (state: RootState) => state.query.checkedUsersForQuery
  )

  const handleChangeActiveShareEdgeLogToView = (log) => {
    dispatch(setActiveShareEdgeLogToView(log))
    dispatch(fetchStoryLogItem())
  }

  const handleToggle = (ase: StoryLogShareEdge) => () => {
    const { owner, log } = ase

    const currentIndex = checkedUsersForQuery.indexOf(owner["id"])
    const newChecked = [...checkedUsersForQuery]

    if (currentIndex === -1) {
      newChecked.push(owner["id"])
      handleChangeActiveShareEdgeLogToView(log["id"])
    } else {
      newChecked.splice(currentIndex, 1)
    }

    dispatch(setCheckedUsersForQuery(newChecked))
    dispatch(addTranscriptToQuery())
  }

  const { state, controls } = usePlayer({
    defaultSrc: {
      itemId: activeStoryLogItem?.id,
      itemModel: AudioModel.StoryLogItem
    }
  })

  const controlsRef = useRef(controls)

  useEffect(() => {
    const { current } = controlsRef
    return () => {
      current.pause()
    }
  }, [activeStoryLogItem, controlsRef])

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: activeShareEdges.length * 56 + 128,
        width: "100%"
      }}>
      <div
        style={{
          flexDirection: "column",
          width: "50%",
          height: "100%",
          paddingRight: "5%"
        }}>
        <P>Share Edge Owners</P>
        <List>
          {activeShareEdges.map((ase) => {
            const labelId = `checkbox-list-secondary-label-${ase.owner["id"]}`
            return (
              <ListItem
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={handleToggle(ase)}
                    checked={
                      checkedUsersForQuery.indexOf(
                        ase.owner["id"] as Partial<User>
                      ) !== -1
                    }
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                }
                disablePadding>
                <ListItemButton
                  style={{ maxWidth: "100%" }}
                  onClick={() =>
                    handleChangeActiveShareEdgeLogToView(ase.log["id"])
                  }
                  component="p">
                  <ListItemText
                    style={{ maxWidth: "100%", overflow: "hidden" }}
                    primary={ase.owner["profile"].givenName}
                  />
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </div>
      <div style={{ flexDirection: "column", width: "100%" }}>
        <div
          style={{
            width: "100%",
            height: "10%",
            paddingBottom: "4px"
          }}>
          <P>Ai Summary</P>
        </div>
        <div
          style={{
            width: "100%",
            height: "40%",
            paddingBottom: "8px",
            overflowY: "scroll"
          }}>
          {!!activeStoryLogItem && (
            <P style={{ color: "black" }}>
              {!!activeStoryLogItem.aiSummary
                ? activeStoryLogItem.aiSummary
                : ""}
            </P>
          )}
        </div>
        <div
          style={{
            width: "100%",
            height: "15%",
            paddingBottom: "4px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <P>Transcript</P>
          {!!activeStoryLogItem && activeStoryLogItem?.hasAudio ? (
            <ButtonGroup>
              <Button
                onClick={() =>
                  state?.paused ? controls.play() : controls.pause()
                }>
                {state?.paused ? <PlayCircleOutline /> : <PauseCircleOutline />}
              </Button>
            </ButtonGroup>
          ) : null}
        </div>
        <div
          style={{
            width: "100%",
            height: "35%",
            paddingBottom: "8px",
            overflowY: "scroll"
          }}>
          {!!activeStoryLogItem && (
            <P style={{ color: "black" }}>{activeStoryLogItem.transcript}</P>
          )}
        </div>
      </div>
    </div>
  )
}
