import type { AxiosInstance, AxiosResponse } from "axios"
import type { AppThunk, RootState } from "core/reducer"

import type { Material } from "@considr-it/ponder-entities"

type MaterialThunkData = {
  id?: string
  payload?: Partial<Material>
  promise?: Promise<AxiosResponse<any>>
}

export const generateMaterialThunk = ({
  setIsSaving,
  setActiveMaterial,
  setMaterials,
  getMaterials = (state: RootState, transport: AxiosInstance) =>
    transport
      .get<Array<Material>>("material", {
        params: { hydrate: !!state }
      })
      .then((r) => r.data)
      .catch(() => null),
  onSelector = (state: RootState) => state.editor
}) => {
  const fetchMaterials =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const { activeMaterial } = onSelector(getState())

      const data = await getMaterials(getState(), transport)

      if (!data) return

      dispatch(setMaterials(data))

      if (!activeMaterial && data.length > 0) {
        dispatch(setActiveMaterial(data[0]))
      }
    }

  const createMaterial =
    (): AppThunk =>
    async (dispatch, _, { transport }) => {
      const resp = await transport.post("material")

      if (resp.status !== 200) return

      dispatch(setActiveMaterial(resp.data.material))
      dispatch(fetchMaterials())
    }

  const cloneMaterial =
    (id: string): AppThunk =>
    async (dispatch, _, { transport }) => {
      const resp = await transport.post("material/clone", {
        id
      })

      if (resp.status !== 200) return

      dispatch(setActiveMaterial(resp.data.material))
      dispatch(fetchMaterials())
    }

  const refreshActiveMaterial =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const { activeMaterial } = onSelector(getState())

      if (!activeMaterial?.id) return

      const resp = await transport.get("material", {
        params: { id: activeMaterial?.id, hydrate: true }
      })

      if (resp.status !== 200) return

      dispatch(setActiveMaterial(resp.data))
    }

  const updateMaterial =
    (data: MaterialThunkData): AppThunk =>
    async (dispatch, getState, { transport }) => {
      dispatch(setIsSaving(true))

      const { activeMaterial } = onSelector(getState())

      const resp = await (data.promise || transport.patch("material", data))
      if (resp.status !== 200) return
      if (data.id === activeMaterial.id) {
        dispatch(refreshActiveMaterial())
        dispatch(fetchMaterials())
      }

      dispatch(setIsSaving(false))
    }

  const removeMaterial =
    (id: string): AppThunk =>
    async (dispatch, _, { transport }) => {
      dispatch(setIsSaving(true))

      const resp = await transport.delete("material", {
        params: {
          id
        }
      })
      if (resp.status !== 200) return

      dispatch(fetchMaterials())

      dispatch(setIsSaving(false))
    }

  return {
    fetchMaterials,
    createMaterial,
    updateMaterial,
    removeMaterial,
    cloneMaterial,
    refreshActiveMaterial
  }
}
