import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"

import type { Material } from "@considr-it/ponder-entities"

import type { RemoteState } from "shared/redux/loading"

import { generateMaterialThunk } from "./material-thunk"

export interface EditorState extends RemoteState {
  activeMaterial: Material
  materials: Array<Material>
  isSaving: boolean
  uploadedRecord?: string
  uploading?: boolean
}

const initialState: EditorState = {
  activeMaterial: null,
  materials: null,
  isLoading: false,
  isSaving: false,
  error: null
}

export const initialEditorReducers = {
  setActiveMaterial: (
    state: EditorState,
    { payload }: PayloadAction<Material>
  ) => {
    state.activeMaterial = payload
  },
  setMaterials: (
    state: EditorState,
    { payload }: PayloadAction<Array<Material>>
  ) => {
    state.materials = payload
  },
  setIsSaving: (state: EditorState, { payload }: PayloadAction<boolean>) => {
    state.isSaving = payload
  },
  setIsLoading: (state: EditorState, { payload }: PayloadAction<boolean>) => {
    state.isLoading = payload
  }
}
const editor = createSlice({
  name: "editor",
  initialState,
  reducers: initialEditorReducers
})

export const { setActiveMaterial, setMaterials, setIsSaving, setIsLoading } =
  editor.actions

export const editorReducer = editor.reducer

export const {
  fetchMaterials,
  cloneMaterial,
  createMaterial,
  removeMaterial,
  updateMaterial
} = generateMaterialThunk(editor.actions)

export type EditorActions = typeof editor.actions
