import { List, styled } from "@mui/material"
import type { RootState } from "core/reducer"
import type { FC } from "react"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Virtuoso } from "react-virtuoso"

import { fetchClientEdges } from "../redux/clientele-slice"
import { ClientListBar } from "./client-list-bar"
import { ClientListItem } from "./client-list-item"

const ClientListContainer = styled(List)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const ClientList: FC = () => {
  const dispatch = useDispatch()
  const coachClientEdges = useSelector(
    (state: RootState) => state.clientele.coachClientEdges
  )
  const activeAuthor = useSelector(
    (state: RootState) => state.clientele.activeAuthor
  )

  const [filter, setFilter] = useState<string>("")

  useEffect(() => {
    if (!coachClientEdges) {
      dispatch(fetchClientEdges())
    }
  }, [coachClientEdges, dispatch])

  useEffect(() => {
    dispatch(fetchClientEdges())
  }, [activeAuthor, dispatch])

  return (
    <ClientListContainer
      subheader={<ClientListBar filter={filter} setFilter={setFilter} />}>
      <Virtuoso
        data={coachClientEdges?.filter((ce) => ce.clientEmail.includes(filter))}
        components={{
          Footer: () => (
            <div
              style={{
                padding: "1rem",
                textAlign: "center",
                height: "50vh"
              }}>
              - End reached -
            </div>
          )
        }}
        itemContent={(index, data) => (
          <ClientListItem key={data.id + index} coachClientEdge={data} />
        )}
      />
    </ClientListContainer>
  )
}
