import { CancelScheduleSend, Check, Reply } from "@mui/icons-material"
import { Button, ButtonGroup, IconButton } from "@mui/material"
import type { GridColDef } from "@mui/x-data-grid"
import { DataGrid } from "@mui/x-data-grid"
import { DateTime } from "luxon"
import { useState } from "react"

import type { ClientMetric, CoachClientEdge } from "@considr-it/ponder-entities"
import { useCore } from "@considr-it/ponder-shared"

import { usePonderServerSWR } from "shared/hooks/use-ponder-server"

const commonColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  hideSortIcons: true
}

const columns: GridColDef[] = (
  [
    {
      field: "name",
      headerName: "Inquiry",
      flex: 1,
      disableColumnMenu: false,
      hideSortIcons: false,
      minWidth: 240
    },
    {
      field: "assigned",
      headerName: "Assigned",
      align: "center",
      valueFormatter: (params) => {
        if (!params.value) return ""
        const date = DateTime.fromISO(params.value?.toString())
        return date.toFormat("MMM d")
      }
    },
    {
      field: "responses",
      headerName: "Responses",
      width: 120,
      align: "center"
    },
    {
      field: "shared",
      headerName: "Shared",
      align: "center",
      valueFormatter: (params) => {
        if (!params.value) return ""
        const date = DateTime.fromISO(params.value?.toString())
        return date.toFormat("MMM d")
      }
    },
    {
      field: "isReplied",
      headerName: "Replied",
      type: "boolean",
      renderCell: (params) => {
        const { reviewUri } = params.row as ClientMetric
        //TODO: figure what the good fix for this would be since a package update made params.getValue() not a method anymore Rel #2581
        const isShared = false //!!params.getValue(params.id, "shared")

        if (!params.value) {
          if (!isShared) return ""

          return (
            <IconButton
              title="Click to reply"
              onClick={() => {
                window.open(
                  reviewUri,
                  "Storied Coach Recording Tool",
                  "height=720,width=420"
                )
              }}>
              <Reply />
            </IconButton>
          )
        }

        return <Check />
      }
    },
    {
      field: "isReviewed",
      headerName: "Reviewed",
      type: "boolean",
      renderCell: (params) => {
        if (!params.value) return ""

        return <Check />
      }
    },
    {
      field: "engagement",
      headerName: "Engagement",
      width: 120,
      valueFormatter: (params) => {
        if (!params.value || typeof params.value !== "number") return ""
        const percentage = ((params.value / 5.0) * 100).toFixed(0)
        return `${percentage}%`
      }
    }
  ] as GridColDef[]
).map((col) => ({ ...commonColumnConfig, ...col }))

// make a function to generate random uuid
// const uuid = () => {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
//     const r = (Math.random() * 16) | 0,
//       v = c === "x" ? r : (r & 0x3) | 0x8
//     return v.toString(16)
//   })
// }

// const rows = [
//   {
//     id: uuid(),
//     name: "Inquiry 1",
//     assigned: "2021-8-1",
//     responses: "2/5",
//     sharedDate: "2020-9-1",
//     replied: true,
//     reviewed: true,
//     engagement: 2
//   },
//   {
//     id: uuid(),
//     name: "Inquiry 2",
//     assigned: "2021-8-2",
//     responses: "4/5",
//     sharedDate: "2020-9-1",
//     replied: true,
//     reviewed: false,
//     engagement: 4
//   }
// ]

// for (let i = 3; i < 2; i++) {
//   rows.push({
//     id: uuid(),
//     name: `Inquiry ${i}`,
//     assigned: "2021-8-" + i,
//     responses: Math.ceil(Math.random() * 5) + "/5",
//     sharedDate: "2021-8-" + (i + 1),
//     replied: true,
//     reviewed: i % 10 === 0,
//     engagement: Math.ceil(Math.random() * 5)
//   })
// }

const fullHeight = 52 * 8 + 128

export function ClientDataGrid({ coachClientEdge = {} as CoachClientEdge }) {
  const { transport } = useCore()
  const [selectedList, setSelectedList] = useState([])

  const { data = [], revalidate } = usePonderServerSWR<Array<ClientMetric>>(
    `/coach/client/metric?clientEdgeId=${coachClientEdge.id}`
  )

  return (
    <div
      style={{
        height: data.length < 9 ? data.length * 52 + 128 : fullHeight,
        width: "100%"
      }}>
      <DataGrid
        onStateChange={(state) => {
          setSelectedList(
            state.selection.map((id) => state.rows.idRowsLookup[id])
          )
        }}
        disableVirtualization
        rows={data}
        columns={columns}
        pageSizeOptions={[8]}
        rowCount={8}
        //pageSizeOptions={8}
        //rowsPerPageOptions={[8, 16, 32, 64]}
        checkboxSelection
      />
      <ButtonGroup
        size="small"
        disabled={selectedList.length === 0}
        style={{
          position: "absolute",
          bottom: 32,
          left: 160
        }}>
        <Button
          disabled={selectedList.some((s) => s.shared || !s.assigned)}
          onClick={async () => {
            // console.log(selectedList)
            const payload = {
              materialIdList: selectedList.map((s) => s.materialId),
              coachClientEdgeId: coachClientEdge.id,
              unassign: true
            }

            // console.log(payload)
            await transport.patch("/coach/assign", payload)

            revalidate()
          }}
          title="Cancel assigned inquiry with no share back">
          <CancelScheduleSend />
        </Button>
      </ButtonGroup>
    </div>
  )
}
