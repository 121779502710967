import type { AxiosInstance } from "axios"
import type { AppThunk, RootState } from "core/reducer"

import type { Material } from "@considr-it/ponder-entities"

import { setActiveShareEdgeLogToView } from "./query-slice"

export const generateMaterialThunk = ({
  setActiveMaterial,
  setActiveShareEdges,
  setActiveStory,
  setActiveStoryLogItem,
  setMaterials,
  setQueryResult,
  setIsLoading,
  setCheckedTranscriptsForQuery,
  getMaterials = (state: RootState, transport: AxiosInstance) =>
    transport
      .get<Array<Material>>("material", {
        params: { hydrate: !!state }
      })
      .then((r) => r.data)
      .catch(() => null)
}) => {
  const fetchMaterials =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const data = await getMaterials(getState(), transport)

      if (!data) return

      dispatch(setMaterials(data))
    }

  const fetchShareEdges =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const { activeMaterial } = getState().query
      if (!activeMaterial) return
      try {
        const resp = await transport.get(`material/share/${activeMaterial._id}`)
        dispatch(setActiveShareEdges(resp.data))
      } catch (error) {
        if (error.response.status === 404) {
          alert("No share edges found for this material.")
        } else {
          alert(error.message)
        }
      }
    }

  const fetchStory =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const { activeMaterial } = getState().query
      if (!activeMaterial) return
      try {
        if (!activeMaterial.published) return
        const resp = await transport.get(
          `story?id=${activeMaterial.published["_id"]}`
        )

        dispatch(setActiveStory(resp.data))
      } catch (error) {
        console.log("something went wrong in fetch story")
        alert(error.message)
      }
    }
  const fetchStoryLogItem =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const { activeShareEdgeLogToView, activeStoryItem } = getState().query
      try {
        const resp = await transport.get(
          `llm/${activeShareEdgeLogToView}/${activeStoryItem.id}`
        )

        dispatch(setActiveStoryLogItem(resp.data))
      } catch (error) {
        if (error.response.status === 404) {
          alert("No story log item found for this story item")
        } else {
          alert(error.message)
        }
      }
    }
  const addTranscriptToQuery =
    (): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const {
        activeShareEdgeLogToView,
        activeStoryItem,
        checkedTranscriptsForQuery
      } = getState().query
      try {
        const resp = await transport.get(
          `llm/${activeShareEdgeLogToView}/${activeStoryItem.id}`
        )

        const currentIndex = checkedTranscriptsForQuery.indexOf(
          resp.data.transcript
        )
        const newChecked = [...checkedTranscriptsForQuery]

        if (currentIndex === -1) {
          newChecked.push(resp.data.transcript)
        } else {
          newChecked.splice(currentIndex, 1)
        }
        dispatch(setCheckedTranscriptsForQuery(newChecked))
      } catch (error) {
        alert(error.message)
      }
    }
  const sendQuery =
    (query: string): AppThunk =>
    async (dispatch, getState, { transport }) => {
      const {
        activeStoryItem,
        checkedUsersForQuery,
        checkedTranscriptsForQuery
      } = getState().query
      try {
        dispatch(setIsLoading(true))
        const resp = await transport.post(`llm/query`, {
          users: checkedUsersForQuery,
          storyItem: activeStoryItem,
          transcripts: checkedTranscriptsForQuery,
          query
        })
        dispatch(setQueryResult(resp.data))
      } catch (error) {
        alert(error.message)
      }
      dispatch(setIsLoading(false))
    }
  const clearActiveSelections = (): AppThunk => async (dispatch) => {
    dispatch(setActiveShareEdges([]))
    dispatch(setActiveStory(null))
    dispatch(setActiveStoryLogItem(null))
    dispatch(setCheckedTranscriptsForQuery([]))
    dispatch(setActiveMaterial(null))
    dispatch(setQueryResult(null))
    dispatch(setActiveShareEdgeLogToView(null))
  }

  return {
    fetchMaterials,
    fetchShareEdges,
    fetchStory,
    fetchStoryLogItem,
    sendQuery,
    addTranscriptToQuery,
    clearActiveSelections
  }
}
