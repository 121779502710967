import { useState } from "react"

import type { AudioRecordBase } from "@considr-it/ponder-entities"
import { AudioModel } from "@considr-it/ponder-enums"

import { useGlobal } from "shared/hooks/use-global"

export const useAudioUpload = (
  audioItem: AudioRecordBase,
  audioModel = AudioModel.StoryItem
) => {
  const { transport } = useGlobal()
  const [uploadedRecord, setUploadedRecord] = useState<string>()
  const [uploading, setUploading] = useState(false)

  const uploadRecord = async (file: File) => {
    try {
      setUploadedRecord(null)

      setUploading(true)

      const {
        data: { signedURL, newFilePath }
      } = await transport.get(
        `audio/upload/getSignedURL/${audioModel}/${
          audioItem?.id
        }/${encodeURIComponent(file.name)}`
      )

      await fetch(signedURL, {
        method: "PUT",
        body: await file.arrayBuffer(),
        headers: { "Content-Type": "text/plain" }
      })

      const {
        data: { audio }
      } = await transport.post("/audio/uploadComplete", {
        itemModel: audioModel,
        itemId: audioItem?.id,
        newFilePath
      })

      setUploading(false)

      setUploadedRecord(audio)
    } catch (_) {
      alert("Upload Record error. Please try again later.")
    }
  }

  return {
    uploading,
    uploadedRecord,
    uploadRecord
  }
}

export type AudioUploaderHook = ReturnType<typeof useAudioUpload>
