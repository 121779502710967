import {
  ExpandMore,
  PersonAddDisabledRounded,
  PersonRounded
} from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  ButtonGroup,
  Chip,
  styled
} from "@mui/material"
import { useDispatch } from "react-redux"

import type { CoachClientEdge, User } from "@considr-it/ponder-entities"
import { Container, Typography } from "@considr-it/ponder-shared"

import { removeClient } from "../redux/clientele-slice"
import { ClientDataGrid } from "./client-datagrid"
import { EditClientButton } from "./edit-client-button"

const ClientListItemContainer = styled(Accordion)`
  margin: 16px;
`

export const ClientListItem = ({
  coachClientEdge: ce = {} as CoachClientEdge
}) => {
  const dispatch = useDispatch()

  const client = ce.client as User

  return (
    <ClientListItemContainer square TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Container.FlexRowShrinkWrap
          style={{
            width: "100%",
            justifyContent: "space-between"
          }}>
          <Container.FlexCol
            style={{
              maxWidth: "100%"
            }}>
            <Chip
              variant="outlined"
              color="primary"
              onClick={() => {
                window.open(`mailto:${ce.clientEmail}`, "_blank")
              }}
              avatar={
                client ? (
                  <Avatar src={client.profile.imageUrl} />
                ) : (
                  <Avatar>
                    <PersonRounded />
                  </Avatar>
                )
              }
              label={
                <Typography.H3>{`${client?.profile.name || ""} <${
                  ce.clientEmail
                }>`}</Typography.H3>
              }
            />

            <Container.UnshrinkDiv style={{ width: 8, height: 8 }} />
            {ce.clientPhone ? (
              <div>
                <Chip
                  onClick={() => {
                    window.open(`tel:${ce.clientPhone}`, "_blank")
                  }}
                  label={<Typography.H3>{ce.clientPhone}</Typography.H3>}
                />
              </div>
            ) : null}

            <Container.UnshrinkDiv style={{ width: 8, height: 8 }} />
          </Container.FlexCol>

          <Container.FlexRow>
            <ButtonGroup variant="outlined" orientation="vertical" size="small">
              <EditClientButton coachClientEdge={ce} />
              <Button
                variant="outlined"
                color="error"
                title="Remove client"
                onClick={() => dispatch(removeClient(ce))}>
                <PersonAddDisabledRounded />
              </Button>
            </ButtonGroup>
            <Container.UnshrinkDiv style={{ width: 8, height: 8 }} />
          </Container.FlexRow>
        </Container.FlexRowShrinkWrap>
      </AccordionSummary>
      <AccordionDetails>
        <ClientDataGrid coachClientEdge={ce} />
      </AccordionDetails>
    </ClientListItemContainer>
  )
}
