import { Button, TextField } from "@mui/material"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { Redirect } from "react-router-dom"

import { useGlobal } from "shared/hooks/use-global"

import {
  CardContainer,
  DetailContainer,
  ProfileContent,
  ProfileControls,
  ProfilePImageContainer
} from "./profile"

const AdminPage = () => {
  const { pingData, isAdmin, account, changeAccountRole } = useGlobal()
  const [userEmail, setUserEmail] = useState("")

  const changeUserToCoach = async () => {
    await changeAccountRole(userEmail)
    setUserEmail("")
  }

  if (!isAdmin) {
    return <Redirect to={"/"} />
  }
  return (
    <div className="root-content">
      <div className="content">
        <h2>
          Welcome to the Storied Super Admin Page, {account.profile.givenName}!
        </h2>

        <p>Version {pingData.version}</p>
        <CardContainer>
          <DetailContainer>
            <ProfileContent>
              <Typography component="h5" variant="h5">
                {account.profile.name}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {account.mainEmail}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {account.role.toUpperCase()}
              </Typography>
            </ProfileContent>
            <ProfileControls>
              <TextField
                onChange={(e) => setUserEmail(e.target.value)}
                value={userEmail}
                style={{ marginRight: "24px", width: "90%" }}
                placeholder="User's Email"></TextField>
              <Button variant="contained" onClick={changeUserToCoach}>
                Create a new coach
              </Button>
            </ProfileControls>
          </DetailContainer>

          <ProfilePImageContainer
            image={account.profile.imageUrl}
            title="User avatar"
          />
        </CardContainer>
      </div>
    </div>
  )
}

export default {
  Page: AdminPage,
  link: "/admin",
  name: "User"
} as IPage
