import {
  AssignmentReturned,
  Link as LinkIcon,
  Lock,
  PersonAdd,
  Public,
  School as TemplateIcon,
  Share
} from "@mui/icons-material"
import DatePicker from "@mui/lab/DatePicker"
import TimePicker from "@mui/lab/TimePicker"
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Select,
  TextField,
  Tooltip
} from "@mui/material"
import copyToClipboard from "copy-text-to-clipboard"
import type { RootState } from "core/reducer"
import { DateTime } from "luxon"
import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import type { Material, User } from "@considr-it/ponder-entities"
import { FrequencyOption, MaterialAccess } from "@considr-it/ponder-enums"
import { SystemDialog } from "@considr-it/ponder-shared"

import { useGlobal } from "shared/hooks/use-global"

import { fetchClientEdges } from "features/clientele/redux/clientele-slice"

import { useStoryEditor } from "../hooks/use-story-editor"
import { ClientAutocompleteInput } from "./client-autocomplete-input"

export const materialAccessLabelMap = {
  [MaterialAccess.Template]: "Template",
  [MaterialAccess.Public]: "Advertised",
  [MaterialAccess.Private]: "Unlisted",
  [MaterialAccess.Restricted]: "Restricted"
}

export const materialAccessStringMap = {
  [MaterialAccess.Template]:
    "Listed on Ponder for other coaches to copy and modify",
  [MaterialAccess.Public]:
    "Advertised on Ponder in your profile (and potentially other places in Ponder) and as a template for other coaches to copy and modify",
  [MaterialAccess.Private]: "Anyone on the internet can open this link",
  [MaterialAccess.Restricted]: "Only people added can open with this link"
}

export const materialAccessIconMap = {
  [MaterialAccess.Template]: <TemplateIcon fontSize="small" />,
  [MaterialAccess.Public]: <Public fontSize="small" />,
  [MaterialAccess.Private]: <Share fontSize="small" />,
  [MaterialAccess.Restricted]: <Lock fontSize="small" />
}

const numericPositionMap = ["th", "st", "nd", "rd", "th"]

export const MaterialAccessEditGroup = () => {
  const { transport } = useGlobal()
  const dispatch = useDispatch()

  const {
    story,
    published,
    material,
    updateMaterial,
    isSaving: disabled,
    publishMaterial,
    linkUrl,
    setLinkUrl
  } = useStoryEditor()
  const [frequency, setFrequency] = useState(FrequencyOption.OneTime)
  const [scheduleDate, setScheduleDate] = useState(DateTime.now())
  const [isSaving, setIsSaving] = useState(false)
  const [open, setOpen] = useState(false)
  const [access, setAccess] = useState(material?.access)
  const coachClientEdges = useSelector(
    (state: RootState) => state.clientele.coachClientEdges
  )
  const [openTooltip, setOpenTooltip] = useState(false)

  const [clientAssignMap, setClientAssignMap] = useState<
    Record<string, boolean>
  >({})

  useEffect(() => {
    if (open) {
      setScheduleDate(DateTime.now())
      setFrequency(FrequencyOption.OneTime)
    }
  }, [open])

  useEffect(() => {
    if (!coachClientEdges) {
      dispatch(fetchClientEdges())
    }
  }, [coachClientEdges, dispatch])

  const createLinks = useCallback(async () => {
    const res = await transport.post("shortLink", {
      id: published["_id"],
      route: "reflection"
    })
    setLinkUrl(res.data.shortLink)
  }, [published, transport, setLinkUrl])

  useEffect(() => {
    if (!!published) {
      createLinks()
    }
  }, [material, createLinks, published])

  const hasNewAssignment =
    clientAssignMap &&
    Object.keys(clientAssignMap).filter((k) => !!clientAssignMap[k]).length > 0

  const resetForms = () => {
    setAccess(material.access)
    setClientAssignMap({})
  }

  const updateAccess = async ({ assignment = false } = {}) => {
    setIsSaving(true)

    if (assignment) {
      if (hasNewAssignment) {
        const weekdayOfMonth = Math.ceil(
          (scheduleDate.day / scheduleDate.daysInMonth) * 4.0
        )
        const confirmMessage =
          frequency === FrequencyOption.OneTime
            ? `The inquiry will be delivered on ${scheduleDate.toLocaleString(
                DateTime.DATETIME_SHORT
              )}`
            : frequency === FrequencyOption.Weekly
            ? `The inquiry will be delivered weekly every ${
                scheduleDate.weekdayLong
              } at ${scheduleDate.toFormat("t")}`
            : `The inquiry will be delivered monthly on the ${weekdayOfMonth}${
                numericPositionMap[weekdayOfMonth]
              } ${scheduleDate.weekdayLong} at ${scheduleDate.toFormat("t")}`

        if (!window.confirm(confirmMessage)) {
          setIsSaving(false)
          return
        }
      }

      await transport
        .post("/coach/assign", {
          materialId: material.id,
          accountIds: coachClientEdges
            .filter(
              (ce) =>
                Object.keys(clientAssignMap).includes(ce.id) &&
                clientAssignMap[ce.id] === true
            )
            .map((ce) => (ce.client as User).id),
          scheduleDate,
          frequency
        })
        .catch((e) => {
          console.error(e)
        })
    }

    // check if access was mutated
    if (access !== material.access) {
      const payload: Partial<Material> = { access }
      switch (access) {
        case MaterialAccess.Private:
          payload.promptShare = true
          payload.rating = false
          break
        case MaterialAccess.Public:
          payload.promptShare = false
          payload.rating = true
          break
      }

      dispatch(
        updateMaterial({
          id: material.id,
          payload
        })
      )
    }

    setOpen(false)
    setIsSaving(false)
  }

  if (!material) return null

  return (
    <>
      <Tooltip
        arrow
        open={openTooltip}
        placement="bottom"
        title={
          !!published
            ? materialAccessStringMap[access]
            : "Please publish the inquiry"
        }>
        <ButtonGroup
          color="primary"
          variant="outlined"
          onMouseOver={() => setOpenTooltip(true)}
          onMouseLeave={() => setOpenTooltip(false)}>
          <Button
            disabled={disabled || !published}
            color="primary"
            variant="outlined"
            startIcon={materialAccessIconMap[access]}
            onClick={async () => {
              if (!story.published) {
                if (
                  window.confirm(
                    "You have not published your most recent changes. Would you like to re-publish so your users can access the most up-to-date version of this inquiry?"
                  )
                ) {
                  const pub = await publishMaterial()
                  if (pub === null) return
                }
              }
              resetForms()
              setOpen(true)
            }}>
            Share
          </Button>
        </ButtonGroup>
      </Tooltip>
      {/* <LocalizationProvider dateAdapter={DateAdapter}> */}
      <Dialog
        open={open}
        onClose={(_e, reason) => {
          if (!reason) return
          if (
            (hasNewAssignment || access !== material.access) &&
            SystemDialog.confirm(
              "Unsaved data",
              "You have unsaved data. Are you sure you want to close?"
            )
          )
            return

          resetForms()
          setOpen(false)
        }}>
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row"
          }}>
          <Button variant="contained" size="small" color="primary">
            <PersonAdd />
          </Button>
          <span style={{ marginLeft: 16 }}>Assign your inquiry</span>
        </DialogTitle>
        <DialogContent
          sx={{
            maxHeight: 120
          }}>
          <ClientAutocompleteInput
            checkedClientMap={clientAssignMap}
            onChange={setClientAssignMap}
          />
        </DialogContent>
        {hasNewAssignment && (
          <DialogActions
            style={{ justifyContent: "space-between", padding: "0 24px" }}>
            <DatePicker
              label="Date"
              minDate={DateTime.now()}
              inputFormat="MM/dd/yyyy"
              value={scheduleDate}
              onChange={setScheduleDate}
              renderInput={(params) => (
                <TextField
                  size="small"
                  style={{
                    maxWidth: "45%",
                    width: 160
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true
                  }}
                />
              )}
            />
            <TimePicker
              label="Time"
              value={scheduleDate}
              onChange={setScheduleDate}
              renderInput={(params) => (
                <TextField
                  size="small"
                  style={{
                    maxWidth: "50%",
                    width: 160
                  }}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true
                  }}
                />
              )}
            />
          </DialogActions>
        )}
        {hasNewAssignment && (
          <DialogActions
            sx={{
              "> div": {
                width: "100%",
                zIndex: 10000
              }
            }}
            style={{ justifyContent: "space-between", padding: "8px 24px" }}>
            {/* <TimezoneSelect
              timezones={{
                "Pacific/Midway": "Samoa",
                "Pacific/Honolulu": "Hawaii",
                "America/Juneau": "Alaska",
                "America/Boise": "Mountain Time",
                "America/Chicago": "Central Time",
                "America/Detroit": "Eastern Time",
                "America/St_Johns": "Labrador",
                "America/Sao_Paulo": "Brasilia",
                "America/Godthab": "Greenland",
                "America/Los_Angeles": "Pacific Time",
                "America/Barbados": "Atlantic Time",
                "Atlantic/Azores": "Azores",
                "Atlantic/Cape_Verde": "Cape Verde Islands",
                "Europe/London": "London",
                "Europe/Brussels": "Paris",
                "Europe/Moscow": "Moscow",
                "Asia/Tehran": "Tehran",
                "Asia/Dubai": "Abu Dhabi",
                "Asia/Karachi": "Islamabad",
                "Asia/Kolkata": "Mumbai",
                "Asia/Kathmandu": "Kathmandu",
                "Asia/Dhaka": "Dhaka",
                "Asia/Rangoon": "Yangon Rangoon",
                "Asia/Bangkok": "Hanoi",
                "Asia/Kuala_Lumpur": "Singapore",
                "Asia/Seoul": "Seoul",
                "Australia/Darwin": "Darwin",
                "Australia/Sydney": "Sydney",
                "Asia/Magadan": "Magadan",
                "Pacific/Fiji": "Fiji Islands",
                "Pacific/Tongatapu": "Nuku'alofa"
              }}
              value={scheduleDate.zone.name}
              onChange={(tz) => {
                setScheduleDate(scheduleDate.setZone(tz.value))
              }}
            /> */}
          </DialogActions>
        )}
        <DialogActions
          style={{ justifyContent: "space-between", padding: "8px 24px" }}>
          {/* {pickedClient ? (
              <FormControl size="small">
                <InputLabel id="frequency-label">Frequency</InputLabel>
                <Select
                  value={frequency}
                  onChange={(e) =>
                    setFrequency(e.target.value as FrequencyOption)
                  }
                  labelId="frequency-label"
                  label="Frequency">
                  {[
                    FrequencyOption.OneTime,
                    // FrequencyOption.BiWeekly,
                    FrequencyOption.Weekly
                    // FrequencyOption.BiMonthly,
                    // FrequencyOption.Monthly
                  ].map((f) => (
                    <MenuItem key={f} value={f}>
                      {f}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <div />
            )} */}
          <div />
          <ButtonGroup>
            <Button
              onClick={() => {
                resetForms()
                setOpen(false)
              }}
              disabled={isSaving}>
              {hasNewAssignment ? "Cancel" : "Close"}
            </Button>

            <Button
              variant="contained"
              onClick={() => updateAccess({ assignment: true })}
              color="primary"
              disabled={isSaving}>
              {hasNewAssignment ? "Assign" : "Done"}
            </Button>
          </ButtonGroup>
        </DialogActions>

        <Divider />

        <DialogTitle>
          <Button size="small" color="primary">
            <LinkIcon />
          </Button>
          <span style={{ marginLeft: 16 }}>Get link</span>
        </DialogTitle>

        <DialogContent
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            overflow: "visible",
            paddingBottom: 0
          }}>
          <TextField
            dir="rtl"
            style={{
              width: "100%",
              marginRight: 16
            }}
            color="primary"
            size="small"
            variant="outlined"
            label="Inquiry Link"
            value={linkUrl}
          />
          <Button
            size="small"
            color="primary"
            onClick={(e) =>
              copyToClipboard(linkUrl, {
                target: e.currentTarget
              })
            }>
            <AssignmentReturned />
          </Button>
        </DialogContent>

        <DialogContent
          style={{
            flexDirection: "row",
            display: "flex"
          }}>
          <Button size="small">{materialAccessIconMap[access]}</Button>

          <div style={{ marginLeft: 16 }}>
            <Select
              native
              size="small"
              value={access}
              onChange={(e) => {
                setAccess(e.target.value as MaterialAccess)
              }}>
              {[
                MaterialAccess.Private,
                MaterialAccess.Template,
                MaterialAccess.Public
              ].map((v) => (
                <option value={v} key={v}>
                  {materialAccessLabelMap[v]}
                </option>
              ))}
            </Select>
            <p style={{ fontSize: 12, width: 280, height: 48 }}>
              {materialAccessStringMap[access]}
            </p>
          </div>
        </DialogContent>
      </Dialog>
      {/* </LocalizationProvider> */}
    </>
  )
}
