import { ExpandMore } from "@mui/icons-material"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  styled
} from "@mui/material"
import type { RootState } from "core/reducer"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import type { StoryItem } from "@considr-it/ponder-entities"
import { StoryItemLayout } from "@considr-it/ponder-enums"
import { Container, Typography } from "@considr-it/ponder-shared"

import { setActiveStoryItem, setActiveStoryLogItem } from "../redux/query-slice"
import { StoryItemData } from "./story-item-data"

const ClientListItemContainer = styled(Accordion)`
  margin: 16px;
`

export const StoryListItem = ({ storyItem = {} as StoryItem }) => {
  const dispatch = useDispatch()

  const activeStoryItem = useSelector(
    (state: RootState) => state.query.activeStoryItem
  )

  const [expanded, setExpanded] = useState(
    activeStoryItem?.id === storyItem?.id
  )

  const handleActiveStoryItem = () => {
    dispatch(setActiveStoryLogItem(null))
    if (activeStoryItem?.id === storyItem?.id && expanded) {
      setExpanded(false)
    } else {
      setExpanded(true)
      dispatch(setActiveStoryItem(storyItem))
    }
  }

  useEffect(() => {
    setExpanded(activeStoryItem?.id === storyItem?.id)
  }, [activeStoryItem, storyItem?.id])

  if (storyItem.layout !== StoryItemLayout.Default) return null

  return (
    <ClientListItemContainer
      expanded={expanded}
      square
      TransitionProps={{ unmountOnExit: true }}>
      <AccordionSummary
        onClick={() => handleActiveStoryItem()}
        expandIcon={<ExpandMore />}>
        <Container.FlexRowShrinkWrap
          style={{
            width: "100%",
            justifyContent: "space-between"
          }}>
          <Container.FlexCol
            style={{
              maxWidth: "100%"
            }}>
            <Chip
              variant="outlined"
              color="primary"
              label={<Typography.H3>{storyItem.prompt}</Typography.H3>}
            />

            <Container.UnshrinkDiv style={{ width: 8, height: 8 }} />
          </Container.FlexCol>

          <Container.FlexRow>
            <Container.UnshrinkDiv style={{ width: 8, height: 8 }} />
          </Container.FlexRow>
        </Container.FlexRowShrinkWrap>
      </AccordionSummary>
      <AccordionDetails>
        <StoryItemData />
      </AccordionDetails>
    </ClientListItemContainer>
  )
}
