import type { Action, ThunkAction } from "@reduxjs/toolkit"
import { combineReducers } from "@reduxjs/toolkit"

import type { GlobalContextProps } from "shared/hooks/use-global"

import { clienteleReducer } from "features/clientele/redux/clientele-slice"
import { editorReducer } from "features/editor/redux/editor-slice"
import { exploreReducer } from "features/explore/redux/explore-slice"
import { queryReducer } from "features/query/redux/query-slice"

export const rootReducer = combineReducers({
  editor: editorReducer,
  explore: exploreReducer,
  clientele: clienteleReducer,
  query: queryReducer
})

export type RootState = ReturnType<typeof rootReducer>
export type AppThunk = ThunkAction<
  void,
  RootState,
  GlobalContextProps,
  Action<string>
>
