import styled from "@emotion/styled"
import type { RootState } from "core/reducer"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { BaseMaterialList } from "features/editor/components/material-list"
import {
  clearActiveSelections,
  fetchMaterials,
  setActiveMaterial
} from "features/query/redux/query-slice"

const StoryListContainer = styled.div`
  flex: 0 0 280px;
  height: 100%;
  overflow: auto;
`

export const StoryList = () => {
  const dispatch = useDispatch()

  const materials = useSelector((state: RootState) => state.query.materials)

  const activeMaterial = useSelector(
    (state: RootState) => state.query.activeMaterial
  )

  useEffect(() => {
    if (materials !== null) return

    dispatch(fetchMaterials())
  }, [materials, dispatch])

  return (
    <StoryListContainer>
      <BaseMaterialList
        showUnpublished={false}
        onPress={(material) => {
          dispatch(clearActiveSelections())
          dispatch(setActiveMaterial(material))
        }}
        materials={materials}
        activeMaterial={activeMaterial}
      />
    </StoryListContainer>
  )
}
