import type { RootState } from "core/reducer"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { BaseMaterialList } from "features/editor/components/material-list"
import { useStoryViewer } from "features/editor/hooks/use-story-viewer"
import {
  fetchMaterials,
  setActiveMaterial
} from "features/explore/redux/explore-slice"

export const ExploreMaterialList = () => {
  const dispatch = useDispatch()

  const activeAuthor = useSelector(
    (state: RootState) => state.explore.activeAuthor
  )
  const { material, materials } = useStoryViewer({
    onSelector: (state) => state.explore
  })

  useEffect(() => {
    if (!activeAuthor) return
    if (material?.owner !== activeAuthor.id) {
      dispatch(fetchMaterials())
    }
  }, [activeAuthor, material, dispatch])

  return (
    <BaseMaterialList
      onPress={(material) => dispatch(setActiveMaterial(material))}
      materials={materials}
      activeMaterial={material}
    />
  )
}
