import { styled } from "@mui/material"
import { useDispatch } from "react-redux"

import { useWideVisible } from "shared/hooks/use-screen-size"

import {
  cloneMaterial,
  removeMaterial,
  setActiveMaterial,
  updateMaterial
} from "features/editor/redux/editor-slice"

import {
  StoryEditorContext,
  useStoryEditorProvider
} from "../hooks/use-story-editor"
import { QuickAndDirtyAccordionButton } from "./accordion"
import {
  CopyAndOpenButtonGroup,
  DeleteButton,
  FeaturesToggleGroup,
  PublishButtonGroup,
  RefreshAndCloneButtonGroup
} from "./edit-panel-group"
import { MaterialAccessEditGroup } from "./material-access-editor"
import { StoryEditor } from "./story-editor"

export const EditorStoryViewerContainer = styled("div")`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const EditPanel = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  & > * {
    margin: 8px !important;
  }
`

export const EditorStoryViewer = () => {
  const dispatch = useDispatch()

  const storyEditorHook = useStoryEditorProvider({
    updateMaterial
  })

  const { material, story, isLoading, isSaving } = storyEditorHook

  const [hideEditPanel, setHideEditPanel] = useWideVisible()

  if (!story || isLoading) return null

  return (
    <StoryEditorContext.Provider value={storyEditorHook}>
      <EditorStoryViewerContainer>
        <EditPanel
          style={hideEditPanel ? { height: 0, display: "none" } : undefined}>
          <DeleteButton
            disabled={isSaving}
            onPress={() => {
              dispatch(setActiveMaterial(null))
              dispatch(removeMaterial(material.id))
            }}
          />
          <FeaturesToggleGroup />

          <MaterialAccessEditGroup />

          <CopyAndOpenButtonGroup />

          {/* <AudioButtonGroup material={material} /> */}

          <RefreshAndCloneButtonGroup
            onClone={() => {
              dispatch(cloneMaterial(material.id))
            }}
          />

          <PublishButtonGroup />
        </EditPanel>

        <QuickAndDirtyAccordionButton
          irresponsive
          isHidden={hideEditPanel}
          onToggle={() => setHideEditPanel((h) => !h)}
        />
        <StoryEditor />
      </EditorStoryViewerContainer>
    </StoryEditorContext.Provider>
  )
}
