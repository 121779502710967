import { useMediaQuery } from "@mui/material"
import type { Dispatch, SetStateAction } from "react"
import { useEffect, useState } from "react"

export const useWide = () => useMediaQuery("(min-width: 650px)")

export const useWideVisible = (): [
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const isWideScreen = useWide()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(!isWideScreen)
  }, [isWideScreen])

  return [isVisible, setIsVisible]
}
